import React, { useState } from 'react'
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap'
// import { useSelector } from 'react-redux'
//import images
import avatar1 from '../../assets/images/sallatk.svg'
import { Link } from 'react-router-dom'
// import { createSelector } from 'reselect'
import { useTranslation } from 'react-i18next'

const ProfileDropdown = () => {
  const { t } = useTranslation()
  // const profiledropdownData = createSelector(
  //   (state: any) => state.Profile.user,
  //   (user) => user
  // )
  // Inside your component
  // const user = useSelector(profiledropdownData)

  // const [userName, setUserName] = useState(t('Admin'))
  // const [userRole, setUserRole] = useState(t('Store Owner'))

  // useEffect(() => {
  //   const authUSer: any = sessionStorage.getItem('authUser')
  //   if (authUSer) {
  //     const obj: any = JSON.parse(authUSer)
  //     setUserName(obj.name + (obj.lastName ?? ''))
  //     setUserRole(obj.is_store_owner === 1 ? t('Store Owner') : t('Moderator'))
  //   }
  // }, [userName, userRole, user, t])

  //Dropdown Toggle
  const [isProfileDropdown, setIsProfileDropdown] = useState(false)
  const toggleProfileDropdown = () => {
    setIsProfileDropdown(!isProfileDropdown)
  }
  return (
    <React.Fragment>
      <Dropdown
        isOpen={isProfileDropdown}
        toggle={toggleProfileDropdown}
        className="ms-sm-3 header-item topbar-user">
        <DropdownToggle tag="button" type="button" className="btn">
          <span className="d-flex align-items-center">
            <img className="rounded-circle header-profile-user" src={avatar1} alt="Header Avatar" />
            <span className="text-start ms-xl-2">
              <span className="d-none d-xl-inline-block ms-1 fw-medium user-name-text">
                {/* {userName} */}
              </span>
              <span className="d-none d-xl-block ms-1 fs-13 text-muted user-name-sub-text">
                {/* {userRole} */}
              </span>
            </span>
          </span>
        </DropdownToggle>
        <DropdownMenu container={'body'} className="dropdown-menu-end">
          <h6 className="dropdown-header">{/* {t('Welcome')} {userName}! */}</h6>
          <DropdownItem className="p-0">
            <Link to="/profile" className="dropdown-item">
              <i className="mdi mdi-account-circle text-muted fs-16 align-middle me-1"></i>
              <span className="align-middle"> {t('Profile')}</span>
            </Link>
          </DropdownItem>
          <DropdownItem className="p-0">
            <Link to="/apps-chat" className="dropdown-item">
              <i className="mdi mdi-message-text-outline text-muted fs-16 align-middle me-1"></i>{' '}
              <span className="align-middle">{t('Messages')}</span>
            </Link>
          </DropdownItem>
          <DropdownItem className="p-0">
            <Link to={'#'} className="dropdown-item">
              <i className="mdi mdi-calendar-check-outline text-muted fs-16 align-middle me-1"></i>{' '}
              <span className="align-middle">{t('Taskboard')}</span>
            </Link>
          </DropdownItem>
          <DropdownItem className="p-0">
            <Link to="/pages-faqs" className="dropdown-item">
              <i className="mdi mdi-lifebuoy text-muted fs-16 align-middle me-1"></i>{' '}
              <span className="align-middle">{t('Help')}</span>
            </Link>
          </DropdownItem>
          <div className="dropdown-divider"></div>
          <DropdownItem className="p-0">
            <Link to="/pages-profile" className="dropdown-item">
              <i className="mdi mdi-wallet text-muted fs-16 align-middle me-1"></i>{' '}
              <span className="align-middle">
                {t('Balance')} : <b>$5971.67</b>
              </span>
            </Link>
          </DropdownItem>
          <DropdownItem className="p-0">
            <Link to="/pages-profile-settings" className="dropdown-item">
              <span className="badge bg-success-subtle text-success mt-1 float-end">
                {t('New')}
              </span>
              <i className="mdi mdi-cog-outline text-muted fs-16 align-middle me-1"></i>{' '}
              <span className="align-middle">{t('Settings')}</span>
            </Link>
          </DropdownItem>
          <DropdownItem className="p-0">
            <Link to="/auth-lockscreen-basic" className="dropdown-item">
              <i className="mdi mdi-lock text-muted fs-16 align-middle me-1"></i>{' '}
              <span className="align-middle">{t('Lock screen')}</span>
            </Link>
          </DropdownItem>
          <DropdownItem className="p-0">
            <Link to="/logout" className="dropdown-item">
              <i className="mdi mdi-logout text-muted fs-16 align-middle me-1"></i>{' '}
              <span className="align-middle" data-key="t-logout">
                {t('Logout')}
              </span>
            </Link>
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  )
}

export default ProfileDropdown
