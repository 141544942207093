import { Button, Col, Container, Row } from 'reactstrap'
import { useTranslation } from 'react-i18next'
import { Input, Submit, ImageUpload, Switch } from 'Components/Form'
import CreateHook from 'helpers/CreateHook'
import PopUp from '../../../Components/PopUp'

type CreatePartner = Omit<Partner, 'id' | 'mobile_image'>

const CreateModal = ({ close, show, onSuccess }: any) => {
  const { t } = useTranslation()

  // prepare hock
  const { register, handleSubmit, errors, create, loading, closeUploading, openUploading } =
    CreateHook<CreatePartner>('/success/partners', {
      title: '',
      link: '',
      published: 0,
      image: ''
    })

  const submit = async (data: CreatePartner) => {
    const response = await create(data)
    if (response.status) {
      close()
      onSuccess()
    }
  }

  return (
    <PopUp title={t('Create new partner')} show={show} close={close}>
      <form onSubmit={handleSubmit(submit)}>
        <Container fluid>
          <Row>
            <Col md={12}>
              <Input
                label={t('Title')}
                placeholder={t('Enter title')}
                model={register('title', {
                  required: t('Title is required'),
                  minLength: {
                    value: 3,
                    message: t('Title must be bigger than or equal 3')
                  }
                })}
                error={errors.title}
              />
            </Col>
            <Col md={12}>
              <Input
                label={t('Link')}
                placeholder={t('Enter link')}
                model={register('link', {
                  required: t('Link is required'),
                  minLength: {
                    value: 3,
                    message: t('Link must be bigger than or equal 3')
                  }
                })}
                error={errors.link}
              />
            </Col>
            <Col md={12}>
              <ImageUpload
                label={t('Image')}
                placeholder={t('Select file')}
                model={register('image')}
                error={errors.image}
                onUpload={openUploading}
                onFininishUpload={closeUploading}
              />
            </Col>
            <Col md={12}>
              <Switch
                label={t('Published')}
                model={register('published')}
                error={errors.published}
                defaultChecked={false}
              />
            </Col>
          </Row>
        </Container>

        <div className="hstack gap-2 justify-content-end">
          <Button className="btn btn-link btn-secondary fw-medium" outline onClick={close}>
            <i className="ri-close-line me-1 align-middle"></i> {t('Close')}
          </Button>
          <Submit loading={loading} label={t('Create')} />
        </div>
      </form>
    </PopUp>
  )
}
export default CreateModal
