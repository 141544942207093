import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { Button, Col, Container, Row, UncontrolledTooltip } from 'reactstrap'

import BreadCrumb from 'Components/Common/BreadCrumb'
import IndexTable, { FilterItem, HeaderColumn } from 'Components/IndexTable'
import ImageWithFallback from 'helpers/ImageComponent'
import FetchHock from 'helpers/FetchHock'
import { useState } from 'react'
import UpdateModal from '../Components/UpdateModal'

const StocksList = () => {
  const { t } = useTranslation()

  const [itemIdUpdate, changeItemIdUpdate] = useState<number>()
  const [showUpdate, changeShowUpdate] = useState<boolean>(false)

  const openUpdate = () => changeShowUpdate(true)
  const closeUpdate = () => changeShowUpdate(false)

  document.title = 'Stocks Management | Sallatk'

  const fetchHook = FetchHock<StockItem, any>({ path: '/stocks' })

  const headerColumns: HeaderColumn[] = [
    {
      title: 'Id'
    },
    {
      title: 'Product',
      accessor: 'product'
    },
    {
      title: 'Quantity',
      accessor: 'quantity'
    },
    {
      title: 'Feature',
      accessor: 'feature'
    },
    {
      title: 'Feature Option',
      accessor: 'feature_option'
    },
    {
      title: 'Created by',
      accessor: 'created_by'
    },
    {
      title: 'Order Id',
      accessor: 'order_id'
    },
    {
      title: 'Branch Name',
      accessor: 'branch_id'
    },
    {
      title: 'Created at',
      accessor: 'created_at'
    },
    {
      title: 'View'
    }
  ]

  const [feature_id, setFeature_id] = useState(null)

  const filterItems: FilterItem[] = [
    {
      type: 'asyncSelect',
      col: 6,
      placeholder: t('Select') + ' ' + t('Product'),
      path: 'products',
      action: (value) => fetchHook.filter('product_id', value)
    },
    {
      type: 'asyncSelect',
      col: 6,
      placeholder: t('Select') + ' ' + t('Branch'),
      path: 'branches',
      action: (value) => fetchHook.filter('branch_id', value)
    },

    {
      type: 'asyncSelect',
      col: 6,
      placeholder: t('Select') + ' ' + t('Feature'),
      path: 'features',
      action: (value) => {
        setFeature_id(value)
        fetchHook.filter('feature_id', value)
      }
    },
    {
      type: 'asyncSelect',
      col: 6,
      placeholder: t('Select') + ' ' + t('Feature Option'),
      path: `featureOptions?filter[feature_id]=${feature_id}`,
      action: (value) => fetchHook.filter('feature_option_id', value),
      isDisabled: !feature_id
    },
    {
      type: 'asyncSelect',
      col: 6,
      placeholder: t('Select') + ' ' + t('Order'),
      path: `orders`,
      action: (value) => fetchHook.filter('order_id', value)
    }
  ]

  return (
    <div className="page-content">
      <Container fluid>
        <BreadCrumb />
        <Row>
          <Col lg={12}>
            <IndexTable
              title={t('Stocks Management')}
              headerColumns={headerColumns}
              {...fetchHook}
              filters={filterItems}>
              {fetchHook?.items?.map((item, index: number) => (
                <tr key={index}>
                  <td>
                    <h5 className="fs-14 mb-1">
                      <Link to={`/stocks/${item.id}`} className="text-body text-uppercase">
                        #{item.id}{' '}
                      </Link>
                    </h5>
                  </td>
                  <td>
                    <div className="d-flex gap-1 ">
                      <div className="avatar-sm bg-light rounded">
                        <ImageWithFallback
                          src={item?.product?.web_main_image}
                          alt={item?.product?.title}
                          className="img-fluid d-block"
                        />
                      </div>
                      <div>
                        <div>{`${item?.product?.title || ''}`}</div>
                      </div>
                    </div>
                  </td>
                  <td>{item?.quantity}</td>
                  <td>{item.feature?.name}</td>
                  <td>{item?.feature_option?.title}</td>
                  <td>
                    <div className="d-flex ">
                      <div className="avatar-sm bg-light rounded">
                        <ImageWithFallback
                          src={item?.created_by?.image}
                          alt={item?.created_by?.name}
                          className="img-fluid d-block"
                        />
                      </div>
                      <div>
                        <div>{`${item?.created_by?.name || ''} ${
                          item?.created_by?.lastname || ''
                        }`}</div>
                        <div>{`${item?.created_by?.dialing_code || ''}${
                          item?.created_by?.phone || ''
                        }`}</div>
                      </div>
                    </div>
                  </td>
                  <td>
                    <Link to={`/orders/${item.order_id}`}>{item.order_id}</Link>
                  </td>

                  <td>
                    <div>
                      {item?.branch?.name}{' '}
                      {item?.branch?.main === 1 ? (
                        <span>
                          <UncontrolledTooltip placement="top" target={'main-branch-' + index}>
                            {t('Main')}
                          </UncontrolledTooltip>
                          <i
                            id={'main-branch-' + index}
                            className="ri ri-home-2-fill text-success"></i>
                        </span>
                      ) : (
                        ''
                      )}
                    </div>
                  </td>

                  <td>{item.created_at}</td>
                  <td>
                    <div className="hstack gap-3 flex-wrap">
                      <Button
                        outline
                        color="primary"
                        onClick={() => {
                          openUpdate()
                          changeItemIdUpdate(item.id)
                        }}>
                        <i className="ri-edit-2-line fs-16"></i>
                      </Button>
                    </div>
                  </td>
                  {/* <td>
                    <div className="hstack gap-3 flex-wrap">
                      <UncontrolledDropdown>
                        <DropdownToggle href="#" className="btn btn-secondary btn-sm" tag="button">
                          <i className="ri-more-fill" />
                        </DropdownToggle>
                        <DropdownMenu container={'body'} className="dropdown-menu-end">
                          <DropdownItem tag={Link} to={`/roles/${item.id}`}>
                            <i className="ri-edit-2-line me-2"></i>
                            {t('Edit')}
                          </DropdownItem>

                          <DropdownItem divider />
                          <DropdownItem onClick={() => fetchHock.openDelete(item.id)}>
                            <span className="text-danger">
                              <i className="ri-delete-bin-line me-2"></i>
                              {t('Delete')}
                            </span>
                          </DropdownItem>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </div>
                  </td> */}
                </tr>
              ))}
            </IndexTable>
          </Col>
        </Row>
      </Container>

      {showUpdate && (
        <UpdateModal
          close={closeUpdate}
          show={openUpdate}
          onSuccess={fetchHook.fetch}
          itemId={itemIdUpdate}
        />
      )}
    </div>
  )
}

export default StocksList
