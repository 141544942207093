import { Container } from 'reactstrap'

import { useTranslation } from 'react-i18next'
import BreadCrumb from 'Components/Common/BreadCrumb'
import { useNavigate } from 'react-router'
import ButtonCards from '../component/ButtonCards'

function BasicSettings() {
  const { t } = useTranslation()

  return (
    <div className="page-content">
      <Container fluid>
        <BreadCrumb />
        <ButtonCards
          data={[
            { name: 'Payment Methods', url: '/payment-methods', icon: 'bx bx-credit-card' },
            { name: 'Shipping', url: '/shipping', icon: 'las la-shipping-fast' },
            {
              name: 'Branches',
              url: '/branches',
              icon: 'las la-code-branch'
            },
            { name: 'Languages', url: '/languages', icon: 'ri ri-earth-line' },
            { name: 'Currencies', url: '/currencies', icon: 'ri ri-money-dollar-circle-line' },
            { name: 'Store Settings', url: '/settings?group=store', icon: 'bx bxs-store-alt' },
            { name: 'SEO', url: '/homepage-seo', icon: 'bx bx-search-alt-2' },
            { name: 'maintenance', url: '/settings?group=Maintainance', icon: 'bx bx-wrench' },         
          ]}
        />
      </Container>
    </div>
  )
}

export default BasicSettings
