// react elements
import { useTranslation } from 'react-i18next'
// bootstrap elements
import { Button, Col, Container, Row } from 'reactstrap'
// reqired componnent
import { Input, Submit, ImageUpload, Switch } from 'Components/Form'
import UpdateHock from 'helpers/UpdateHock'
import PopUp from 'Components/PopUp'
// import model type
import Update from '../models/Update'

const UpdateModal = ({ show, close, onSuccess, itemId }: any) => {
  const { t } = useTranslation()

  // prepare hock
  const {
    register,
    handleSubmit,
    errors,
    loading,
    closeUploading,
    openUploading,
    update,
    getValues,
    getLoading
  } = UpdateHock<Update>({
    path: '/article_category',
    initValue: {
      id: null,
      title: '',
      slug: '',
      img_url: '',
      published: 0
    },
    editId: itemId
  })

  const submit = async (data: Update) => {
    const response = await update(data)
    if (response.status) {
      close()
      onSuccess()
    }
  }

  return (
    <PopUp title={t('Update Category')} show={show} close={close} loading={getLoading}>
      <form onSubmit={handleSubmit(submit)}>
        <Container fluid>
          <Row>
            <Col md={12}>
              <ImageUpload
                label={t('Image')}
                model={register('img_url', {
                  required: t('Image is required')
                })}
                error={errors.img_url}
                onUpload={openUploading}
                onFininishUpload={closeUploading}
                value={getValues('web_image')}
              />
            </Col>
            <Col md={12}>
              <Input
                label={t('Title')}
                placeholder={t('Enter title')}
                model={register('title', {
                  required: t('Title is required'),
                  minLength: {
                    value: 3,
                    message: t('Title must be bigger than or equal 3')
                  }
                })}
                error={errors.title}
              />
            </Col>
            <Col md={12}>
              <Input
                label={t('Slug')}
                placeholder={t('Enter slug')}
                model={register('slug', {
                  required: t('Slug is required'),
                  minLength: {
                    value: 3,
                    message: t('Slug must be bigger than or equal 3')
                  }
                })}
                error={errors.slug}
              />
            </Col>
            <Col md={12}>
              <Switch defaultChecked={false} label={t('Published')} model={register('published')} />
            </Col>
          </Row>
        </Container>

        <div className="hstack gap-2 justify-content-end">
          <Button className="btn btn-link btn-secondary fw-medium" outline onClick={close}>
            <i className="ri-close-line me-1 align-middle"></i> {t('Close')}
          </Button>
          <Submit loading={loading} label={t('Update')} />
        </div>
      </form>
    </PopUp>
  )
}
export default UpdateModal
