import axios from 'axios'
import linkGenerator from 'helpers/linkGenerator'
import { useEffect, useState } from 'react'
import { Spinner, Card, Col, Row } from 'reactstrap'

type FileType = {
  path: string
  FormatSize: string
  preview: string
  readyToLoad: boolean
  loading: boolean
  name:
    | {
        file: string
        mobile: string | null
      }
    | string
}

const ImageFile = ({
  mainFile,
  removeFile,
  index,
  onUpload,
  onFininishUpload,
  updateFile
}: {
  mainFile: FileType
  removeFile: (index: number) => void
  updateFile: (index: number, file: FileType) => void
  index: number
  onUpload?: () => void
  onFininishUpload?: () => void
}) => {
  const [file, setFile] = useState<FileType[]>([mainFile])

  const updateProduct = async () => {
    if (onUpload) onUpload()

    const formData = new FormData()
    formData.append('file', file[0] as any)

    try {
      const response = await axios.post(linkGenerator('/fileService'), formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })

      const updatedFile = {
        ...file[0],
        loading: false,
        readyToLoad: false,
        name: response.data.result
      }
      setFile([updatedFile])
    } catch (error) {
      console.error('File upload failed:', error)
      removeFile(index)
    } finally {
      if (onFininishUpload) onFininishUpload()
    }
  }

  useEffect(() => {
    if (file[0].readyToLoad) updateProduct()
    updateFile(index, file[0])
  }, [file[0].readyToLoad])

  useEffect(() => {
    setFile([mainFile])
  }, [mainFile])

  return (
    <Card className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete">
      <div className="p-2">
        <Row className="align-items-center">
          <Col>
            {file[0] && (
              <Row className="align-items-center">
                <Col className="col-auto">
                  <img
                    data-dz-thumbnail=""
                    height="80"
                    className="avatar-sm rounded bg-light"
                    alt={renderFileName(file[0]?.name)}
                    src={file[0]?.preview}
                  />
                </Col>
                <Col>
                  {file[0]?.loading ? (
                    <Spinner size="sm" />
                  ) : (
                    <>
                      <a href="#" className="text-muted font-weight-bold">
                        {renderFileName(file[0]?.name)}
                      </a>
                      <p className="mb-0">
                        <strong>{file[0]?.FormatSize}</strong>
                      </p>
                    </>
                  )}
                </Col>
              </Row>
            )}
          </Col>
          <div className="col-sm-auto">
            <button
              type="button"
              className="btn btn-outline-danger"
              onClick={() => removeFile(index)}>
              <i className="ri-close-circle-line align-middle"></i>
            </button>
          </div>
        </Row>
      </div>
    </Card>
  )
}

export default ImageFile

const renderFileName = (name: string | { file: string; mobile: string | null }) => {
  if (typeof name === 'object') {
    return name.file
  } else {
    return name
  }
}
