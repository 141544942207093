import { Button, Col, Container, Row } from 'reactstrap'
import { useTranslation } from 'react-i18next'
import { Input, Submit, ImageUpload, Switch, Select, DatePicker } from 'Components/Form'
import CreateHook from 'helpers/CreateHook'
import PopUp from '../../../Components/PopUp'

type CreateCustomer = Omit<Customers, 'id'>

const CreateModal = ({ close, show, onSuccess }: any) => {
  const { t } = useTranslation()

  // prepare hock
  const { register, handleSubmit, errors, create, loading, closeUploading, openUploading } =
    CreateHook<CreateCustomer>('/customers', {
      password: '',
      name: '',
      lastname: '',
      email: '',
      dialing_code: '',
      phone: '',
      gender: null,
      is_active: 0,
      image: '',
      web_image: '',
      birthdate: null
    })

  const submit = async (data: CreateCustomer) => {
    const response = await create(data)
    if (response.status) {
      close()
      onSuccess()
    }
  }

  return (
    <PopUp title={t('Create new customer')} show={show} close={close}>
      <form onSubmit={handleSubmit(submit)}>
        <Container fluid>
          <Row>
            <Col md={6}>
              <Input
                label={t('Name')}
                model={register('name', {
                  required: t('Name is required')
                })}
                error={errors.name}
              />
            </Col>
            <Col md={6}>
              <Input
                label={t('Last Name')}
                model={register('lastname', {
                  required: t('Last name is required')
                })}
                error={errors.lastname}
              />
            </Col>
            <Col md={12}>
              <Input
                label={t('Email')}
                model={register('email', {
                  required: t('Email is required'),
                  pattern: {
                    value: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
                    message: t('Invalid email format')
                  }
                })}
                error={errors.email}
              />
            </Col>
            <Col md={12}>
              <Input
                type="password"
                label={t('Password')}
                model={register('password', {
                  required: t('Password is required')
                })}
                error={errors.password}
              />
            </Col>
            <Col md={6}>
              <Input
                label={t('Dialing Code')}
                placeholder={`${t('Enter')} ${t('Dialing code')}`}
                model={register('dialing_code', {
                  required: t('Dialing code is required')
                })}
                error={errors.dialing_code}
              />
            </Col>
            <Col md={6}>
              <Input
                label={t('Phone')}
                placeholder={`${t('Enter')} ${t('Phone')}`}
                model={register('phone', {
                  required: t('Phone is required')
                })}
                error={errors.phone}
              />
            </Col>
            <Col md={6}>
              <Select
                label={t('Gender')}
                placeholder={`${t('Enter')} ${t('Gender')}`}
                model={register('gender')}
                error={errors.gender}
                options={[
                  { value: 'male', label: t('Male') },
                  { value: 'female', label: t('Female') }
                ]}
              />
            </Col>
            <Col md={6}>
              <DatePicker
                label={t('Birthdate')}
                placeholder={`${t('Enter')} ${t('Birthdate')}`}
                model={register('birthdate')}
                error={errors.birthdate}
              />
            </Col>

            <Col md={12}>
              <ImageUpload
                label={t('Image')}
                model={register('image', {
                  required: t('Image is required')
                })}
                error={errors.image}
                onUpload={openUploading}
                onFininishUpload={closeUploading}
              />
            </Col>
            <Col md={12}>
              <Switch label={t('Active')} model={register('is_active')} />
            </Col>
          </Row>
        </Container>

        <div className="hstack gap-2 justify-content-end">
          <Button className="btn btn-link btn-secondary fw-medium" outline onClick={close}>
            <i className="ri-close-line me-1 align-middle"></i> {t('Close')}
          </Button>
          <Submit loading={loading} label={t('Create')} />
        </div>
      </form>
    </PopUp>
  )
}
export default CreateModal
