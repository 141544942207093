import WithCategory from './withCategory/Config'

const config = [
  {
    title: 'With Category',
    key: 'withCategory',
    config: WithCategory
  }
]

export default config
