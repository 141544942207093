import clsx from 'clsx'
import ReactSelect from 'react-select'

const Select = ({
  label,
  placeholder,
  options,
  model,
  error,
  onChange,
  value,
  className,
  components,
  disabled,
  wrapperClassName
}: {
  label?: string
  placeholder?: string
  options?: { label: string; value: string }[]
  model?: any
  error?: any
  onChange?: (value: string) => void
  value?: any
  disabled?: boolean
  className?: string
  components?: any
  wrapperClassName?: string
}) => {
  // id for input focus
  const randumId = Math.random().toString(36).substring(7)
  // name of input
  const name = model?.name

  function handleChange(select: any) {
    model &&
      model?.onChange({
        target: {
          name,
          value: select.value
        }
      })
    onChange && onChange(select.value)
  }
  return (
    <div className={clsx('mb-3', wrapperClassName)}>
      {!!label && (
        <label htmlFor={randumId} className="form-label">
          {label}
        </label>
      )}
      <ReactSelect
        placeholder={placeholder}
        className={
          error ? 'form-control is-invalid p-0 ' + className : 'form-control p-0 ' + className
        }
        defaultValue={options?.find((el) => el.value == value) ?? null}
        onChange={handleChange}
        options={options}
        components={components ? components : null}
        isDisabled={disabled}
        styles={{
          control: (baseStyles) => ({
            ...baseStyles,
            minWidth: 'max-content'
          }),
          dropdownIndicator: (baseStyles) => ({
            ...baseStyles,
            padding: 0
            // display: 'none'
          }),
          indicatorSeparator: (baseStyles) => ({
            ...baseStyles
            // display: 'none'
          }),
          menu: (baseStyles) => ({
            ...baseStyles,
            minWidth: 'max-content'
          }),
          menuList: (baseStyles) => ({
            ...baseStyles,
            minWidth: 'max-content'
          }),
          option: (baseStyles) => ({
            ...baseStyles,
            minWidth: 'max-content'
          })
        }}
      />
      <div className="invalid-feedback">{error?.message}</div>
    </div>
  )
}

export default Select
