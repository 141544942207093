import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { Col, Container, Row } from 'reactstrap'

import BreadCrumb from 'Components/Common/BreadCrumb'
import IndexTable, { FilterItem, HeaderColumn } from 'Components/IndexTable'
import FetchHock from 'helpers/FetchHock'
import ImageWithFallback from 'helpers/ImageComponent'

function FormatCreatedAt(createdAt) {
  const year = createdAt.getFullYear()
  const month = (createdAt.getMonth() + 1).toString().padStart(2, '0') // Months are 0-based
  const day = createdAt.getDate().toString().padStart(2, '0')

  const formattedDate = `${year}-${month}-${day}`
  return formattedDate
}

const ActivitiesList = () => {
  const { t } = useTranslation()

  document.title = 'Activities | Sallatk'

  const fetchHook = FetchHock<Activities, any>({ path: '/activity' })

  const headerColumns: HeaderColumn[] = [
    {
      title: 'Id'
    },
    {
      title: 'Causer'
    },
    {
      title: 'Action'
    },
    {
      title: 'Name'
    },

    {
      title: 'url'
    },
    {
      title: 'Created at'
    },
    {
      title: 'View'
    }
  ]

  const filterItems: FilterItem[] = [
    {
      type: 'asyncSelect',
      col: 3,
      placeholder: t('Select a causer'),
      path: 'moderators',
      action: (value) => fetchHook.filter('causer_id', value)
    },
    {
      type: 'date',
      col: 3,
      placeholder: t('Select start date'),
      action: (value) => {
        const formattedDate = FormatCreatedAt(value[0])
        fetchHook.filter('created_at', formattedDate, 'from')
      }
    },
    {
      type: 'date',
      col: 3,
      placeholder: t('Select end date'),
      action: (value) => {
        const formattedDate = FormatCreatedAt(value[0])
        fetchHook.filter('created_at', formattedDate, 'to')
      }
    },
    {
      type: 'search',
      col: 3,
      placeholder: t('Type a action name'),
      action: (value) => fetchHook.filter('action', value)
      //   iconClass: 'ri ri-user-search-line'
    }
  ]

  return (
    <div className="page-content">
      <Container fluid>
        <BreadCrumb />
        <Row>
          <Col lg={12}>
            <IndexTable
              title={t('Activities')}
              headerColumns={headerColumns}
              {...fetchHook}
              filters={filterItems}>
              {fetchHook?.items?.map((item, index: number) => (
                <tr key={index}>
                  <td>
                    <h5 className="fs-14 mb-1">
                      <Link to={`/activities/${item.id}`} className="text-body text-uppercase">
                        #{item.id}{' '}
                      </Link>
                    </h5>
                  </td>
                  <td>
                    <ImageWithFallback
                      src={item.causer?.image}
                      className="avatar-xs rounded-circle me-2"
                    />
                    {item.causer?.name || '...'}
                    <small className="text-muted d-block">{item.causer?.email}</small>
                  </td>
                  <td>{item.action}</td>
                  <td>{item.name}</td>
                  <td>{item.url}</td>

                  <td>{item.created_at}</td>
                  <td>
                    <div className="hstack gap-3 flex-wrap">
                      <Link color="link" to={`/activities/${item.id}`}>
                        <i className="ri-eye-fill fs-16"></i>
                      </Link>
                    </div>
                  </td>
                </tr>
              ))}
            </IndexTable>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default ActivitiesList
