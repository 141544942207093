// bootstrap elements
import { Spinner } from 'reactstrap'
import { useEffect, useState } from 'react'

// fetch hock logic
import linkGenerator from 'helpers/linkGenerator'
import axios from 'axios'

const FileUpload = ({
  label,
  placeholder,
  model,
  error,
  value,
  onUpload,
  onFininishUpload
}: {
  label: string
  placeholder?: string
  model?: any
  error?: any
  value?: any
  onUpload: () => void
  onFininishUpload: () => void
}) => {
  const randumId = Math.random().toString(36).substring(7)

  const [fileName, setFileName] = useState<string>('')
  const [loading, setLoading] = useState<boolean>(false)
  // const [serverError, setServerEerror] = useState<boolean>(false)

  // watch value and update file
  useEffect(() => setFileName(value), [value])

  const uploadFile = async (event: any) => {
    // Start loading
    setLoading(true)
    onUpload()
    // Assign the file
    const file = event.target.files[0]

    // Prepare form data
    const formData = new FormData()
    formData.append('file', file)

    // Empty the input
    event.target.value = null

    // Upload the image
    try {
      const response = await axios.post(linkGenerator('/fileService'), formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })

      // Update value
      const name = model.name
      model.onChange({
        target: {
          name,
          value: response.data.result
        }
      })
      setFileName(response.data.result)
    } catch (error) {
      // Show error
      showError()
    }

    // Stop loading
    setLoading(false)
    onFininishUpload()
  }

  const removeFile = () => {
    setFileName('')

    // Update value
    const name = model.name
    model.onChange({
      target: {
        name,
        value: ''
      }
    })
  }

  const showError = () => {
    // Open error
    // setServerEerror(true)
    // // Close error after 3 seconds
    // setTimeout(() => setServerEerror(false), 3000)
  }

  return (
    <div className="mb-3">
      <label htmlFor={randumId} className="form-label">
        {label}
      </label>
      <div className="input-group">
        <span className="input-group-text" id="inputGroup-sizing-default">
          {label}
        </span>
        <input type="text" placeholder={placeholder} className="form-control" value={fileName} />
        <input
          className="abslute-mack"
          type="file"
          id={randumId}
          onChange={(event) => uploadFile(event)}
        />
        {loading && <Spinner className="upload-icon" color="secondary" size="sm" />}
        {!loading && fileName && (
          <i onClick={() => removeFile()} className="ri-close-line remove-icon"></i>
        )}
      </div>
      <div className="invalid-feedback">{error?.message}</div>
    </div>
  )
}

export default FileUpload
