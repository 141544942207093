// react elements
import { useTranslation } from 'react-i18next'
// bootstrap elements
import { Button, Col, Container, Row } from 'reactstrap'
// reqired componnent
import { Input, Submit, ImageUpload, Switch } from 'Components/Form'
import CreateHook from 'helpers/CreateHook'
import PopUp from 'Components/PopUp'
// import model type
import Create from '../models/Create'

const CreateModal = ({ close, show, onSuccess }: any) => {
  const { t } = useTranslation()

  const handleTitleChange = (title: string): string => title.replace(/[^\p{L}0-9 _-]/gu, '_')

  // prepare hock
  const {
    register,
    handleSubmit,
    errors,
    create,
    loading,
    closeUploading,
    openUploading,
    setValue
  } = CreateHook<Create>('/article_category', {
    title: '',
    slug: '',
    img_url: '',
    published: 0
  })

  const submit = async (data: Create) => {
    const response = await create(data)
    if (response.status) {
      close()
      onSuccess()
    }
  }

  return (
    <PopUp title={t('Create New Articles Category')} show={show} close={close}>
      <form onSubmit={handleSubmit(submit)}>
        <Container fluid>
          <Row>
            <Col md={12}>
              <ImageUpload
                label={t('Image')}
                model={register('img_url', {
                  required: t('Image is required')
                })}
                error={errors.img_url}
                onUpload={openUploading}
                onFininishUpload={closeUploading}
              />
            </Col>
            <Col md={12}>
              <Input
                label={t('Title')}
                placeholder={t('Enter title')}
                model={register('title', {
                  required: t('Title is required'),
                  minLength: {
                    value: 3,
                    message: t('Title must be bigger than or equal 3')
                  }
                })}
                error={errors.title}
                onChange={(value) => setValue('slug', handleTitleChange(value))}
              />
            </Col>
            <Col md={12}>
              <Input
                label={t('Slug')}
                placeholder={t('Enter slug')}
                model={register('slug', {
                  required: t('Slug is required'),
                  minLength: {
                    value: 3,
                    message: t('Slug must be bigger than or equal 3')
                  }
                })}
                error={errors.slug}
              />
            </Col>
            <Col md={12}>
              <Switch defaultChecked={false} label={t('Published')} model={register('published')} />
            </Col>
          </Row>
        </Container>

        <div className="hstack gap-2 justify-content-end">
          <Button className="btn btn-link btn-secondary fw-medium" outline onClick={close}>
            <i className="ri-close-line me-1 align-middle"></i> {t('Close')}
          </Button>
          <Submit loading={loading} label={t('Create')} />
        </div>
      </form>
    </PopUp>
  )
}
export default CreateModal
