// bootstrap elements
import { Button, Card, Col, Nav, NavItem, NavLink, Spinner, TabContent, TabPane } from 'reactstrap'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

// fetch hock logic
import linkGenerator from 'helpers/linkGenerator'
import axios from 'axios'
import React from 'react'
import showErrorToast from 'helpers/ErorrNotfication'
import clsx from 'clsx'
import FetchHock from 'helpers/FetchHock'
import Masonry from 'react-masonry-component'
import { Link } from 'react-router-dom'

const ImageUpload = ({
  label,
  placeholder,
  model,
  theme = false,
  error,
  value,
  onUpload,
  onFininishUpload,
  anyFiles
}: {
  label: string
  placeholder?: string
  model?: any
  theme?: boolean
  error?: any
  value?: any
  onUpload?: () => void
  onFininishUpload?: () => void
  anyFiles?: boolean
}) => {
  const { t } = useTranslation()
  const formContext = useFormContext()

  const randomId = Math.random().toString(36).substring(7)
  const [fileName, setFileName] = useState<string>('')
  const [fileSize, setFileSize] = useState<number>(0)
  const [image, setImage] = useState<string>(value ?? '')
  const [loading, setLoading] = useState<boolean>(false)
  // const [serverError, setServerEerror] = useState<boolean>(false)
  // watch value and update image
  // useEffect(() => {
  //   setImage(value)
  // }, [value])
  const uploadFile = async (event: any) => {
    // Start loading
    setLoading(true)
    if (onUpload) onUpload()
    // Assign the file
    const file = event.target.files[0]

    // Add file name and file size
    const fileSize = Math.round(file.size / 1000)
    const imageUrl = URL.createObjectURL(file)
    setFileName(file.name)
    setFileSize(fileSize)
    setImage(imageUrl)

    // Prepare form data
    const formData = new FormData()
    formData.append('file', file)
    if (theme) formData.append('type', 'theme')

    // Empty the input
    event.target.value = null

    // Upload the image
    try {
      const response = await axios.post(
        linkGenerator(`/fileService${anyFiles ? '/file' : ''}`),
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
      )

      // Update value
      const name = model.name
      model?.onChange({
        target: {
          name,
          value: theme
            ? {
                url: response?.data?.result?.file,
                mobile: response?.data?.result?.mobile
              }
            : response?.data?.result?.file
        }
      })
      setFileName(response.data.result?.file)
    } catch (error: any) {
      if (error?.response?.data?.message) {
        showErrorToast(error.response.data.message)
      } else {
        showErrorToast("Couldn't upload image")
      }
      showError()
    }

    // Stop loading
    setLoading(false)
    if (onFininishUpload) onFininishUpload()
  }

  const removeFile = () => {
    setFileName('')
    setFileSize(0)
    setImage('')
    setSelectedGalleryImage('')

    // Update value
    const name = model?.name
    model?.onChange({
      target: {
        name,
        value: ''
      }
    })
  }

  const showError = () => {
    showError
    removeFile()
  }

  const [activeTab, setActiveTab] = useState(1)

  const toggleTab = (tab: number) => {
    if (activeTab !== tab) {
      setActiveTab(tab)
    }
  }

  const [selectedGalleryImage, setSelectedGalleryImage] = useState<string>('')
  const handleGalleryImageSelect = ({ value, preview }) => {
    setSelectedGalleryImage(preview)
    setImage(preview)
    // Update the model value
    const name = model?.name
    const currentValue = formContext?.getValues(model?.name)
    model?.onChange({
      target: {
        name,
        value: theme ? { ...currentValue, url: value } : value
      }
    })
  }

  return (
    <div className="mb-3">
      <label htmlFor={randomId} className="form-label">
        {label}
      </label>
      <Nav tabs pills fill>
        <NavItem>
          <NavLink
            className={clsx(activeTab === 1 && 'active', 'cursor-pointer')}
            onClick={() => toggleTab(1)}>
            <i className="las la-upload me-2"></i>
            {t('Upload')}
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={clsx(activeTab === 2 && 'active ', 'cursor-pointer')}
            onClick={() => toggleTab(2)}>
            <i className="las la-images me-2"></i>
            {t('Gallery')}
          </NavLink>
        </NavItem>
      </Nav>
      <TabContent activeTab={activeTab} className="pt-3">
        <TabPane tabId={1}>
          <div className={error ? 'is-invalid file-upload-container' : 'file-upload-container'}>
            {!image ? (
              <React.Fragment>
                <input
                  type="file"
                  className="mainInput"
                  onChange={(event) => {
                    uploadFile(event)
                  }}
                />
                <div className="file-upload-content">
                  <label>
                    {placeholder ? (
                      placeholder
                    ) : (
                      <React.Fragment>
                        {t('Drag & Drop your files or')}{' '}
                        <span className="filepond--label-action"> {t('Browse')} </span>
                      </React.Fragment>
                    )}
                  </label>
                </div>
              </React.Fragment>
            ) : (
              <div className="image-vieower">
                <img src={image || selectedGalleryImage} />
                <div className="overlay">
                  <div className="image-details">
                    {!!fileName && <h5>{fileName}</h5>}
                    {!!fileSize && <p>{fileSize}</p>}
                  </div>
                  <div>
                    {!loading ? (
                      <button className="remove-btn" type="button" onClick={() => removeFile()}>
                        <i className="ri-close-line"></i>
                      </button>
                    ) : (
                      <button className="remove-btn" type="button">
                        <Spinner color="light" size="sm" />
                      </button>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
        </TabPane>
        <TabPane tabId={2}>
          <Gallery
            onSelect={handleGalleryImageSelect}
            theme={theme}
            selected={selectedGalleryImage}
          />
        </TabPane>
      </TabContent>

      <div className="invalid-feedback">{error?.message}</div>
    </div>
  )
}

export default ImageUpload
import GalleryGrid from 'Components/GalleryGrid'
import { useFormContext } from 'react-hook-form'

function Gallery({ onSelect, selected, theme }) {
  const { result, loading, error } = FetchHock<{ url: string; path: string }>({
    path: '/storeGallery'
  })

  return (
    <div
      style={{
        background: '#eee',
        borderRadius: '5px',
        color: '#525151',
        maxHeight: '200px',
        overflowY: 'auto'
      }}>
      <GalleryGrid
        data={result?.data}
        onClickImage={(img) => {
          onSelect({ value: theme ? img.url : img.path, preview: img.url })
        }}
        selectedGalleryImage={selected}
      />
    </div>
  )
}
