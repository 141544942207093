import { Button, Col, Container, Row } from 'reactstrap'
import { useTranslation } from 'react-i18next'
import { Input, Submit, ImageUpload, Switch, Textarea } from 'Components/Form'
import UpdateHock from 'helpers/UpdateHock'
import PopUp from 'Components/PopUp'

const UpdateModal = ({ show, close, onSuccess, itemId }: any) => {
  const { t } = useTranslation()

  type brandtype = {
    id: number | null
    name: string
    image: string | null
    published: boolean
    description: string | null
    web_image?: string
  }

  // init value
  const initForm: brandtype = {
    id: null,
    name: '',
    published: false,
    image: null,
    description: ''
  }

  // prepare hock
  const {
    register,
    handleSubmit,
    errors,
    loading,
    closeUploading,
    openUploading,
    update,
    getValues,
    getLoading
  } = UpdateHock<brandtype>({
    path: '/brands',
    initValue: initForm,
    editId: itemId
  })

  const submit = async (data: brandtype) => {
    const response = await update(data)
    if (response.status) {
      close()
      onSuccess()
    }
  }

  return (
    <PopUp title={t('Update Brand')} show={show} close={close} loading={getLoading}>
      <form onSubmit={handleSubmit(submit)}>
        <Container fluid>
          <Row>
            <Col md={12}>
              <ImageUpload
                label={t('Image')}
                model={register('image', {
                  required: t('Image is required')
                })}
                error={errors.image}
                onUpload={openUploading}
                onFininishUpload={closeUploading}
                value={getValues('web_image')}
              />
            </Col>
            <Col md={12}>
              <Input
                label={t('Title')}
                type="text"
                placeholder={t('Enter title')}
                model={register('name', {
                  required: t('Title is required'),
                  minLength: {
                    value: 3,
                    message: t('Title must be bigger than or equal 3')
                  }
                })}
                error={errors.name}
              />
            </Col>
            <Col md={12}>
              <Textarea
                label={t('Description')}
                placeholder={t('Enter description')}
                model={register('description')}
                error={errors.description}
              />
            </Col>
            <Col md={12}>
              <Switch defaultChecked={false} label={t('Published')} model={register('published')} />
            </Col>
          </Row>
        </Container>

        <div className="hstack gap-2 justify-content-end">
          <Button className="btn btn-link btn-secondary fw-medium" outline onClick={close}>
            <i className="ri-close-line me-1 align-middle"></i> {t('Close')}
          </Button>
          <Submit loading={loading} label={t('Update')} />
        </div>
      </form>
    </PopUp>
  )
}
export default UpdateModal
