import { defaultValues } from 'ThemeManager/Templates/DefaultValues'

export default {
  content: {
    title: {
      ar: 'التطبيق تشوك بازار',
      en: 'The ChawkBazar App'
    },
    subTitle: {
      ar: '<span> شارك مشترياتك <strong>الأفكار</strong> &amp; بلا حدود <strong>بمزيد من الإعجاب</strong> </span>',
      en: '<span> Share Your <strong>Ideas</strong> &amp; Shop Endless <strong>Inspiration</strong> </span>'
    },
    appImage: defaultValues.image,
    appButtons: [
      {
        slug: '/',
        appButton: defaultValues.image
      },
      {
        slug: '/',
        appButton: defaultValues.image
      }
    ]
  }
}
