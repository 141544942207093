import useStaticData from 'hooks/useStaticData'
import useFormOptions from 'hooks/useMenuFormOptions'
import ReactSelect from 'react-select'
import { UseProfile } from 'helpers/UserHooks'
import { useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

function SelectLinkWithPreview({ parentLabel, input }) {
  const { setValue, getValues } = useFormContext()
  const name = parentLabel + input.label
  const initValue = getValues(name)

  const { t } = useTranslation()

  const frontParamName = {
    Category: 'categories',
    Brand: 'brands',
    Product: 'products',
    Article: 'articles',
    staticPages: 'pages'
  }

  const { moduleNameOptions } = useStaticData()
  const { moduleIdOptions, moduleIdOptionsLoading, fetchModuleIds } = useFormOptions()

  const [from, setFrom] = useState<{ label: string; value: string }>()
  const [item, setItem] = useState<{ label: string; value: string }>()

  const [fullUrl, setFullUrl] = useState({
    from: null,
    item: null
  })
  const [UrlPreview, setUrlPreview] = useState(initValue)
  const { userProfile } = UseProfile()
  const storeUrl = userProfile?.store?.url || 'your-store-url'

  const generateUrl = (v: any) => {
    const from = v?.from ? v?.from : ''
    const item = v?.item ? v?.item : ''
    const frontParam = frontParamName[from]

    switch (from) {
      case 'Category':
      case 'Brand':
        const baseUrl = `${storeUrl}/search`
        const params = new URLSearchParams()
        params.append(`filter[${frontParam}][]`, item)
        const url = `${baseUrl}?${params.toString()}`
        return url
      case 'MenuCustomLink':
        return item
      case 'Product':
      case 'Article':
      case 'staticPages':
        return `${storeUrl}/${frontParam}/${item}`

      default:
        return ''
    }
  }

  const changeFullUrl = (fullUrlObj) => {
    const url = generateUrl(fullUrlObj)
    setValue(name, url)
    setFullUrl(fullUrlObj)
    setUrlPreview(url)
  }
  const changeFrom = (v) => {
    fetchModuleIds(v.value)
    setFrom(v)
    changeFullUrl({ from: v.value })
  }
  const changeItem = (v) => {
    setItem(v)
    changeFullUrl({ ...fullUrl, item: v.value })
  }
  return (
    <div>
      <label className="form-label">{t(input.title)}</label>
      <div className="d-flex gap-3 align-items-baseline">
        <ReactSelect
          className={'form-control p-0 '}
          onChange={changeFrom}
          options={
            getValues('categorySlug')
              ? [{ label: getValues('categorySlug'), value: getValues('categorySlug') }]
              : moduleNameOptions
          }
        />

        <ReactSelect
          key={from?.value}
          className={'form-control p-0 '}
          onChange={changeItem}
          options={moduleIdOptions.map((item: any) => {
            return { label: item.title, value: item?.url ?? item.id }
          })}
          disabled={moduleIdOptionsLoading || !from?.value}
        />
      </div>
      <br />
      <p className="d-flex justify-content-center">
        {
          <a href={'https://' + UrlPreview} target="_blank">
            {UrlPreview}
          </a>
        }
      </p>
    </div>
  )
}

export default SelectLinkWithPreview
