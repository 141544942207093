import clsx from 'clsx'
import { useTranslation } from 'react-i18next'

const Input = ({
  label,
  placeholder,
  model,
  error,
  type = 'text',
  onChange,
  className,
  disabled,
  atEnd,
  atBegin,
  atBeginIcon,
  step,
  wrapperClassName,
  horizontal,
  onBlur
}: {
  label?: string
  placeholder?: string
  model?: any
  error?: any
  type?: string
  onChange?: (value: string) => void
  className?: string
  disabled?: boolean
  atEnd?: string
  atBegin?: string | JSX.Element
  atBeginIcon?: string
  step?: string
  wrapperClassName?: string
  horizontal?: boolean
  onBlur?: any
}) => {
  const { t } = useTranslation()
  const randumId = Math.random().toString(36).substring(7)

  return (
    <div className={clsx('mb-3', wrapperClassName, { row: horizontal })}>
      {!!label && (
        <label htmlFor={randumId} className={clsx('form-label', { 'col-md-3': horizontal })}>
          {label}
        </label>
      )}
      <div className={clsx({ 'col-md-9': horizontal })}>
        <div className={error ? 'input-group is-invalid' : 'input-group'}>
          {atBegin && <span className="input-group-text">{atBegin}</span>}
          {atBeginIcon && (
            <span className="input-group-text">
              <i className={atBeginIcon}></i>
            </span>
          )}
          <input
            type={type}
            className={(error ? 'form-control is-invalid' : 'form-control') + ' ' + className}
            id={randumId}
            placeholder={placeholder ?? `${t('Enter')} ${label}`}
            {...model}
            onChange={(event) => {
              if (model?.onChange) model.onChange(event)
              if (onChange) onChange(event.target.value)
            }}
            disabled={disabled}
            onWheel={() => false}
            step={step || '01'}
            onBlur={(event) => {
              if (model?.onBlur) model.onBlur(event)
              if (onBlur) onBlur(event)
            }}
          />
          {atEnd && <span className="input-group-text">{atEnd}</span>}
        </div>
        <div className="invalid-feedback">{error?.message}</div>
      </div>
    </div>
  )
}

export default Input
