import { useState } from 'react'
import { Container, Spinner } from 'reactstrap'
import FetchHock from 'helpers/FetchHock'
import Error from 'Components/IndexTable/partials/Error'
import { useTranslation } from 'react-i18next'
import BreadCrumb from 'Components/Common/BreadCrumb'
import SettingsTabs from './SettingsTabs'
import { TabsData } from '../models/types'
import { useLocation, useParams } from 'react-router'

function GeneralSettings() {
  const { t } = useTranslation()
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const groupParam = searchParams.get('group')

  const fetchHock = FetchHock<TabsData>({
    path: `/settings`,
    initFilter: { group_name: groupParam ? groupParam : '' }
  })

  const [refresh, setRefresh] = useState<boolean>(false)
  const refreshReport = () => setRefresh(!refresh)

  if (!fetchHock || fetchHock?.loading || fetchHock?.error) {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: 'calc(100vh - 250px)'
        }}>
        {fetchHock?.loading ? <Spinner color="primary" /> : <Error />}
      </div>
    )
  }

  return (
    <div className="page-content">
      <Container fluid>
        <BreadCrumb />
        {fetchHock?.items ? <SettingsTabs data={fetchHock.items} /> : null}
      </Container>
    </div>
  )
}

export default GeneralSettings
