// bootstrap elements
import {
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  UncontrolledDropdown
} from 'reactstrap'
import ImageWithFallback from 'helpers/ImageComponent'

// reqired componnent
import BreadCrumb from 'Components/Common/BreadCrumb'
import IndexTable, { FilterItem, HeaderColumn } from 'Components/IndexTable'
// fetch hock logic
import FetchHock from 'helpers/FetchHock'
// translation pakage
import { useTranslation } from 'react-i18next'

import EditLabel from 'Components/EditLabel'

type moderator = {
  id: number
  name: string
  lastname: string
  email: string
  image: string
  role_id: number
  role?: {
    id: number
    name: string
  }
  blocked: boolean
}

const EcommerceOrders = () => {
  const { t } = useTranslation()

  document.title = 'Moderators | Sallatk'

  const {
    items,
    loading,
    error,
    options,
    totalPages,
    totalItems,
    showedItems,
    pagenate,
    sort,
    closeDelete,
    deleteItem,
    deleteId,
    search,
    fetch
  } = FetchHock<moderator>({ path: '/moderators' })

  const headerColumns: HeaderColumn[] = [
    {
      title: 'User',
      accessor: 'name'
    },
    {
      title: 'Role',
      accessor: 'role_id'
    },
    {
      title: 'Blocked',
      accessor: 'blocked'
    },
    {
      title: 'Action'
    }
  ]

  const filterItems: FilterItem[] = [
    {
      type: 'search',
      col: 6,
      placeholder: t('Search for Item'),
      action: (value) => search(value)
    },
    {
      type: 'select',
      col: 6,
      placeholder: t('Select role'),
      options: [
        { label: t('Select role'), value: '' },
        { label: t('Admin'), value: 1 },
        { label: t('Buyer'), value: 2 }
      ],
      action: (value) => console.log(value)
    }
  ]

  return (
    <div className="page-content">
      <Container fluid>
        <BreadCrumb />
        <Row>
          <Col lg={12}>
            <IndexTable
              title={t('Moderators')}
              addString={t('Add Moderator')}
              headerColumns={headerColumns}
              loading={loading}
              error={error}
              options={options}
              totalPages={totalPages}
              totalItems={totalItems}
              showedItems={showedItems}
              pagenate={pagenate}
              deleteId={deleteId}
              closeDelete={closeDelete}
              deleteItem={deleteItem}
              sort={sort}
              filters={filterItems}>
              {items?.map((item, index) => (
                <tr key={index}>
                  <td>
                    <div className="d-flex align-items-center">
                      <div className="flex-shrink-0 me-3">
                        <div className="avatar-sm bg-light rounded p-1">
                          <ImageWithFallback
                            src={item.image}
                            alt={item.name + ' ' + item.lastname}
                            className="img-fluid d-block"
                          />
                        </div>
                      </div>
                      <div className="flex-grow-1">
                        <h5 className="fs-14 mb-1">
                          <a href="/ecommerce/product-details" className="text-body">
                            {item.name + ' ' + item.lastname}{' '}
                          </a>
                        </h5>
                        <p className="text-muted mb-0">{item.email}</p>
                      </div>
                    </div>
                  </td>
                  <td>
                    <EditLabel
                      label={item.role ? item.role.name : ''}
                      path={`/moderators/${item.id}/role`}
                      onSuccess={fetch}
                    />
                  </td>
                  <td>
                    <div className="form-check form-switch-md form-switch form-switch-secondary"></div>
                  </td>
                  <td>
                    <div className="hstack gap-3 flex-wrap">
                      <UncontrolledDropdown>
                        <DropdownToggle href="#" className="btn btn-secondary btn-sm" tag="button">
                          <i className="ri-more-fill" />
                        </DropdownToggle>
                        <DropdownMenu container={'body'} className="dropdown-menu-end">
                          <DropdownItem>
                            <span className="text-muted">
                              <i className="ri-edit-2-line me-2"></i>
                              {t('Edit')}
                            </span>
                          </DropdownItem>

                          <DropdownItem>
                            <span className="text-muted">
                              <i className="ri-lock-password-line me-2"></i>
                              {t('Change password')}
                            </span>
                          </DropdownItem>

                          <DropdownItem divider />
                          <DropdownItem>
                            <span className="text-muted">
                              <i className="ri-delete-bin-line me-2"></i>
                            </span>
                            {t('Delete')}
                          </DropdownItem>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </div>
                  </td>
                </tr>
              ))}
            </IndexTable>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default EcommerceOrders
