import { Button, Col, Container, Row } from 'reactstrap'
import { useTranslation } from 'react-i18next'
import { Input, Submit, ImageUpload, Switch } from 'Components/Form'
import CreateHook from 'helpers/CreateHook'
import PopUp from 'Components/PopUp'
import IBANInput from './IBANInput'

const CreateModalOffline = ({ close, show, onSuccess, data }: any) => {
  const companyType = data?.code
  const { t } = useTranslation()

  const { register, watch, handleSubmit, errors, create, loading, closeUploading, openUploading } =
    CreateHook<any>('/offline-payment', {
      company_type: companyType,
      title: '',
      image_url: '',
      status: 0,
      code: ''
    })

  const submit = async (data) => {
    const response = await create(data)
    if (response.status) {
      close()
      onSuccess()
    }
  }
  return (
    <PopUp title={`${t('Create')} ${t('Contact')}`} show={show} close={close}>
      <form onSubmit={handleSubmit(submit)}>
        <Container fluid>
          <Row>
            <Col md={12}>
              <Input
                label={t('Title')}
                placeholder={t('Enter') + ' ' + t('Title')}
                model={register('title', {
                  required: t('Title is required')
                })}
                error={errors.title}
              />
            </Col>

            <Col md={12}>
              <ImageUpload
                label={t('Image')}
                model={register('image_url', {
                  required: t('Image is required')
                })}
                error={errors.image_url}
                onUpload={openUploading}
                onFininishUpload={closeUploading}
              />
            </Col>
            <Col md={12}>
              <Switch
                label={t('Status')}
                model={register('status', {
                  required: t('Status is required')
                })}
                error={errors.status}
              />
            </Col>
            <Col md={12}>
              <IBANInput isWallet={companyType === 'wallet'} register={register} errors={errors} />
            </Col>
          </Row>
        </Container>

        <div className="hstack gap-2 justify-content-end">
          <Button className="btn btn-link btn-secondary fw-medium" outline onClick={close}>
            <i className="ri-close-line me-1 align-middle"></i> {t('Close')}
          </Button>
          <Submit loading={loading} label={t('Create')} />
        </div>
      </form>
    </PopUp>
  )
}
export default CreateModalOffline
