// react imports
import { useEffect, useRef } from 'react'
// botstrap elemnts
import { Card, CardBody, Col, Container, Row } from 'reactstrap'
//SimpleBar
import SimpleBar from 'simplebar-react'
import { FileUpload, Submit, Textarea } from 'Components/Form'
import CreateHook from 'helpers/CreateHook'
// translation
import { useTranslation } from 'react-i18next'

const TicketDescription = ({ comments, id, fetchData, status }: any) => {
  const { t } = useTranslation()
  type commentType = {
    id?: number
    content: string
    attachment: string | null
    site_ticket_id: number
  }

  // prepare hock
  const {
    register,
    handleSubmit,
    errors,
    create,
    loading,
    closeUploading,
    openUploading,
    setValue
  } = CreateHook<commentType>('/tickets/comment', {
    content: '',
    attachment: null,
    site_ticket_id: id
  })

  const submit = async (data: commentType) => {
    const response = await create(data)
    if (response.status) {
      fetchData()
      setValue('content', '')
      setValue('attachment', null)
    }
  }

  const scrollBarRef = useRef<any>(null)

  const scrollToBottom = () => {
    const scrollableElement = scrollBarRef.current.getScrollElement()
    scrollableElement.scrollTop = scrollableElement.scrollHeight
  }

  useEffect(() => {
    scrollToBottom()
  }, [])

  const getFilenameFromURL = (url: string | URL) => {
    const urlObject = new URL(url)
    const pathnameParts = urlObject.pathname.split('/')
    return pathnameParts[pathnameParts.length - 1]
  }

  return (
    <Card>
      <CardBody className="p-4">
        <h5 className="card-title mb-4">{t('Comments')}</h5>
        <SimpleBar
          style={{ height: '300px' }}
          autoHide={false}
          ref={scrollBarRef}
          className="px-3 mx-n3">
          {comments.map((comment: any, index: any) => (
            <div className="d-flex" key={index}>
              <div className="flex-shrink-0">
                <img src={comment.user_image} alt="" className="avatar-xs rounded-circle" />
              </div>
              <div className="flex-grow-1 ms-3">
                <h5 className="fs-13">
                  {comment.user}
                  <small className="text-muted">{comment.diffForHumans}</small>
                </h5>
                <p className="text-muted">{comment.content}</p>
                <Row className="g-2 mb-3">
                  {comment.attachment.map((file: any, index: any) => (
                    <Col lg={6} key={index}>
                      <Card>
                        <CardBody>
                          <div className="d-flex align-items-center border border-dashed p-2 rounded">
                            <div className="flex-shrink-0 avatar-sm">
                              <div className="avatar-title bg-light rounded">
                                <i className="ri-file-line fs-20 text-primary"></i>
                              </div>
                            </div>
                            <div className="flex-grow-1 ms-3">
                              <h6 className="mb-1">{getFilenameFromURL(file.attachment)}</h6>
                            </div>
                            <div className="hstack gap-3 fs-16">
                              <a href={file.attachment} target="_blank" className="text-muted">
                                <i className="ri-eye-line"></i>
                              </a>
                            </div>
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                  ))}
                </Row>
              </div>
            </div>
          ))}
        </SimpleBar>
        {status === 'closed' ? null : (
          <form onSubmit={handleSubmit(submit)} className="mt-3">
            <Container fluid>
              <Row className="g-3">
                <Col md={12}>
                  <Textarea
                    label={t('Leave a Comment')}
                    placeholder={t('Enter comment')}
                    model={register('content', {
                      required: t('Comment is required'),
                      minLength: {
                        value: 3,
                        message: t('Comment must be bigger than or equal 3')
                      }
                    })}
                    className={'bg-light border-light'}
                    error={errors.content}
                  />
                </Col>
                <Col md={8}>
                  <FileUpload
                    label={t('Attachment')}
                    model={register('attachment')}
                    error={errors.attachment}
                    onUpload={openUploading}
                    onFininishUpload={closeUploading}
                  />
                </Col>
                <Col lg={4} className="text-end">
                  <Submit loading={loading} label={t('Post Comment')} />
                </Col>
              </Row>
            </Container>
          </form>
        )}
      </CardBody>
    </Card>
  )
}

export default TicketDescription
