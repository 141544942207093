import {
  Button,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown
} from 'reactstrap'
import SimpleBar from 'simplebar-react'

import clsx from 'clsx'
import { useRef, useState } from 'react'
import { useOnClickOutside } from 'hooks/useOnClickOutside'
import FetchHock from 'helpers/FetchHock'
import { useParams } from 'react-router-dom'
import DeleteModal from 'Components/Common/DeleteModal'
import UpdateModal from 'pages/Products/Chapters/list/components/UpdateModal'
import CreateModal from 'pages/Products/Chapters/list/components/CreateModal'

function ChaptersView({ chaptersId, setChaptersId, menubarShow, setMenubarShow }) {
  const { courseId } = useParams()
  const {
    items: chapters,
    openDelete,
    closeDelete,
    deleteItem,
    deleteId,
    fetch,
    loading
  } = FetchHock<any>({ path: `/course_product/${courseId}/chapters` })

  const [itemIdUpdate, changeItemIdUpdate] = useState<number>()
  const [showUpdate, changeShowUpdate] = useState<boolean>(false)
  const [showCreate, changeShowCreate] = useState<boolean>(false)
  const openCreate = () => changeShowCreate(true)
  const closeCreate = () => changeShowCreate(false)
  const openUpdate = () => changeShowUpdate(true)
  const closeUpdate = () => changeShowUpdate(false)

  const ref = useRef(null)
  const handleClickOutside = () => {
    setMenubarShow(false)
  }
  useOnClickOutside(ref, handleClickOutside)
  return (
    <>
      <div ref={ref} className={clsx('file-manager-sidebar border', menubarShow && 'menubar-show')}>
        <div className="p-3 d-flex flex-column h-100">
          <div className="mb-3 d-flex align-items-center">
            <h5 className="mb-0 fw-semibold flex-grow-1 ">Chapters</h5>
            <Button size="sm" onClick={() => openCreate()}>
              <i className="ri-add-line"></i>
            </Button>
          </div>
          <div className="search-box">
            <input
              type="text"
              className="form-control bg-light border-light"
              placeholder="Search here..."
            />
            <i className="ri-search-2-line search-icon"></i>
          </div>
          <SimpleBar className="mt-3 mx-n4 px-4 file-menu-sidebar-scroll">
            <ul className="list-unstyled file-manager-menu">
              {chapters?.map((i, index) => {
                return (
                  <li key={index}>
                    <div className="d-flex align-items-center">
                      <a
                        href="#"
                        className={clsx('flex-grow-1', chaptersId === i.id && 'active')}
                        onClick={() => {
                          setChaptersId(i.id)
                          setMenubarShow(false)
                        }}>
                        <i className="ri-file-list-2-line align-bottom me-2"></i>{' '}
                        <span className="file-list-link">{i?.title}</span>
                      </a>
                      <UncontrolledDropdown>
                        <DropdownToggle tag="button" className="btn btn-icon btn-sm dropdown">
                          <i className="ri-more-2-fill fs-16 align-bottom" />
                        </DropdownToggle>
                        <DropdownMenu className="dropdown-menu-end">
                          <DropdownItem
                            className="edit-folder-list"
                            onClick={() => {
                              openUpdate()
                              changeItemIdUpdate(i.id)
                            }}>
                            Edit
                          </DropdownItem>
                          <DropdownItem onClick={() => openDelete(i.id)}>Delete</DropdownItem>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </div>

                    {i?.children && i?.children.length > 0 && (
                      <div className="collapse show" id="collapseExample">
                        <ul className="sub-menu list-unstyled">
                          {i.children.map(() => (
                            <li>
                              <a href="#!">{i?.title}</a>
                            </li>
                          ))}
                        </ul>
                      </div>
                    )}
                  </li>
                )
              })}
            </ul>
          </SimpleBar>
        </div>
      </div>
      {showCreate && <CreateModal close={closeCreate} show={openCreate} onSuccess={fetch} />}
      {showUpdate && (
        <UpdateModal
          close={closeUpdate}
          show={openUpdate}
          onSuccess={fetch}
          itemId={itemIdUpdate}
        />
      )}
      {/* delete component */}
      {deleteId && deleteId > 0 && (
        <DeleteModal
          show={deleteId}
          onDeleteClick={deleteItem}
          onCloseClick={() => {
            if (closeDelete) closeDelete()
          }}
        />
      )}
    </>
  )
}

export default ChaptersView
