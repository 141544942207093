import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

const Navdata = () => {
  const history = useNavigate()

  const [isMedia, setIsMedia] = useState<boolean>(false)
  const [isSecurity, setIsSecurity] = useState<boolean>(false)
  const [isPages, setIsPages] = useState<boolean>(false)
  const [isProducts, setIsProducts] = useState<boolean>(false)
  const [iscurrentState, setIscurrentState] = useState('Dashboard')
  const [isShipping, setIsShipping] = useState<boolean>(false)
  const [isProductSettings, setIsProductSettings] = useState<boolean>(false)

  function updateIconSidebar(e: any) {
    if (e && e.target && e.target.getAttribute('sub-items')) {
      const ul: any = document.getElementById('two-column-menu')
      const iconItems = ul.querySelectorAll('.nav-icon.active')
      const activeIconItems = [...iconItems]
      activeIconItems.forEach((item) => {
        item.classList.remove('active')
        const id = item.getAttribute('sub-items')
        const getID = document.getElementById(id) as HTMLElement
        if (getID) getID.classList.remove('show')
      })
    }
  }

  useEffect(() => {
    document.body.classList.remove('twocolumn-panel')
    if (iscurrentState !== 'media') {
      setIsMedia(false)
    }
    if (iscurrentState !== 'security') {
      setIsSecurity(false)
    }
    if (iscurrentState !== 'pages') {
      setIsPages(false)
    }
    if (iscurrentState !== 'Shipping') {
      setIsShipping(false)
    }
  }, [history, iscurrentState, isMedia, isSecurity, isPages, isShipping])

  const menuItems: any = [
    // {
    //   label: 'Menu',
    //   isHeader: true
    // },
    {
      id: 'dashboard',
      label: 'Dashboards',
      icon: 'las la-tachometer-alt',
      link: '/'
    },
    {
      id: 'POS',
      label: 'POS',
      icon: 'las las la-tablet-alt',
      link: '/pos'
    },
    {
      id: 'products',
      label: 'Products',
      icon: 'las la-boxes',
      link: '/#',
      click: function (e: any) {
        e.preventDefault()
        setIsProducts(!isProducts)
        setIscurrentState('products')
        updateIconSidebar(e)
      },
      stateVariables: isProducts,
      subItems: [
        { id: 'products', label: 'All', link: '/products/list', parentId: 'pages' },
        { id: 'categories', label: 'Categories', link: '/categories/list', parentId: 'pages' },
        {
          id: 'productsSettings',
          label: 'Settings',
          link: '/#',
          isChildItem: true,
          click: function (e: any) {
            e.preventDefault()
            setIsProductSettings(!isProductSettings)
          },
          stateVariables: isProductSettings,
          childItems: [
            {
              id: 1,
              label: 'Labels',
              link: '/products/settings/labels/list',
              parentId: 'productsSettings'
            },
            {
              label: 'Badges',
              link: '/products/settings/badges/list',
              parentId: 'productsSettings'
            },
            {
              id: 4,
              label: 'Tags',
              link: '/products/settings/tags/list',
              parentId: 'productsSettings'
            },
            {
              id: 2,
              label: 'Features',
              link: '/products/settings/features/list',
              parentId: 'productsSettings'
            },
            {
              id: 3,
              label: 'Order forms',
              link: '/products/settings/forms/list'
            }
          ]
        }
      ]
    },
    {
      id: 'orders',
      label: 'Orders',
      icon: 'bx bx-cart',
      link: '/orders/list'
    },
    {
      id: 'transactions',
      label: 'Transactions',
      icon: 'las la-money-bill-alt',
      link: '/transactions/list'
    },
    {
      id: 'themes',
      label: 'Themes',
      icon: 'las la-palette',
      link: '/themes'
    },
    {
      id: 'media',
      label: 'Media',
      icon: 'las la-tv',
      link: '/#',
      click: function (e: any) {
        e.preventDefault()
        setIsMedia(!isMedia)
        setIscurrentState('media')
        updateIconSidebar(e)
      },
      stateVariables: isMedia,
      subItems: [
        { id: 'brands', label: 'Brands', link: '/brands/list', parentId: 'media' },
        { id: 'banners', label: 'Banners', link: '/banners/list', parentId: 'media' },
        { id: 'sliders', label: 'Sliders', link: '/sliders/list', parentId: 'media' },
        {
          id: 'gallery',
          label: 'Gallery',
          icon: 'las la-image',
          link: '/gallery'
        }
      ]
    },
    {
      id: 'tickets',
      label: 'Tickets',
      icon: 'las la-ticket-alt',
      link: '/tickets/list'
    },

    {
      id: 'settings',
      label: 'General Settings',
      icon: 'las la-cog',
      link: '/settings'
    },
    {
      id: 'BasicSettings',
      label: 'Basic Settings',
      icon: 'ri-settings-2-line',
      link: '/basic-settings'
    },
    {
      id: 'AdvancedSettings',
      label: 'Advanced Settings',
      icon: 'ri-equalizer-line',
      link: '/advanced-settings'
    },
    {
      id: 'newsletterSubscribers',
      label: 'Newsletter Subscribers',
      icon: 'las la-envelope',
      link: '/news-letter-subscribers/list'
    },

    {
      id: 'Pricing',
      label: 'Package',
      icon: 'bx bx-purchase-tag',
      link: '/pricing'
    },

    {
      id: 'customers',
      label: 'Customers',
      icon: 'las la-user-friends',
      link: '/customers/list'
    },
    {
      id: 'customersReviews',
      label: 'Customers Reviews',
      icon: 'bx bx-star',
      link: '/customers-reviews/list'
    },
    {
      id: 'activities',
      label: 'Activities Log',
      icon: 'las la-history',
      link: '/activities/list'
    }
  ]
  return <React.Fragment>{menuItems}</React.Fragment>
}
export default Navdata
