import axios, { AxiosError } from 'axios'
import linkGenerator from './linkGenerator'
import { useState } from 'react'
import showError from './ErorrNotfication'
import { useTranslation } from 'react-i18next'
import { keepPreviousData, useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { useNavigate } from 'react-router'

type Options<Filter = {}> = {
  term: string
  page: number
  limit: number
  sort_direction: string
  sort_by: string
  filter: Filter
}

const FetchHock = <ItemType, Filter = {}>({
  path,
  initFilter,
  sortBy,
  limit,
  sortDirection,
  onSuccess,
  enabled = true,
  siteUrl = null
}: {
  path?: string
  initFilter?: any
  sortBy?: string
  limit?: number
  sortDirection?: 'desc' | 'asc'
  onSuccess?: (data: any) => void
  enabled?: boolean
  siteUrl?: 'front' | null
}) => {
  const { i18n } = useTranslation()
  const navigate = useNavigate()

  const queryClient = useQueryClient()

  const [deleteId, setDeleteId] = useState<number | null>(null)

  const [options, setOption] = useState<Options<Filter>>({
    term: '',
    page: 1,
    limit: limit ? limit : 10,
    sort_direction: sortDirection ? sortDirection : 'desc',
    sort_by: sortBy ? sortBy : 'id',
    filter: initFilter ? initFilter : {}
  })

  // open delete method
  const openDelete = (id: number) => setDeleteId(id)

  // close delete method
  const closeDelete = () => setDeleteId(null)

  // delete item
  const deleteItem = async () => {
    // send request to server
    try {
      await axios.delete(linkGenerator(path + '/' + deleteId, siteUrl))
      setDeleteId(null)
      refetch()
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const axiosError = error as AxiosError<{ message: string; status: string }>
        const message = axiosError?.response?.data?.message
        if (message) {
          showError(message)
        }
      }
      setDeleteId(null)
      return
    }
  }

  // this method change page
  const pagenate = (page: number) => {
    // get options data
    const newOptions = { ...options }
    // change page
    newOptions.page = page
    // create option
    setOption(newOptions)
  }

  // this method change search
  let searchInterval: NodeJS.Timeout
  const search = (term: string) => {
    clearTimeout(searchInterval)
    searchInterval = setTimeout(async () => {
      // get options data
      const newOptions = { ...options }
      // change page
      newOptions.page = 1
      newOptions.term = term
      // create option
      setOption(newOptions)
    }, 300)
  }

  // this method sort items
  const sort = (name: string) => {
    // get options data
    const newOptions = { ...options }
    // get first page
    newOptions.page = 1
    newOptions.sort_direction =
      newOptions.sort_direction === 'asc' && newOptions.sort_by === name ? 'desc' : 'asc'
    newOptions.sort_by = name
    // reset option
    setOption(newOptions)
  }

  const filter = (key: keyof Filter, value: any, nestedKey?: string) => {
    // get options data
    const newOptions = { ...options }
    // change page
    if (nestedKey) {
      newOptions.filter[key] = { ...newOptions.filter[key], [nestedKey]: value }
    } else {
      newOptions.filter[key] = value
    }
    // create option
    setOption(newOptions)
  }

  const resetFilter = () => {
    // get options data
    const newOptions = { ...options }
    // change page
    newOptions.filter = initFilter ? initFilter : {}
    // create option
    setOption(newOptions)
  }

  // const [pathState, setPathState] = useState(path)
  // console.log(pathState, path)
  const { data, error, isPending, refetch, isFetching } = useQuery<{
    result: {
      data: ItemType[]
      meta: any
    }
  }>({
    queryKey: [path, options, i18n.language],
    queryFn: async () => {
      try {
        const response = await axios.get(linkGenerator(path, siteUrl), {
          params: options,
          paramsSerializer: (params) => parseParams(params)
        })
        return response.data
      } catch (error) {
        if (axios.isAxiosError(error)) {
          const axiosError = error as AxiosError<{ message: string; status: string }>
          // Check for 403 status and display a custom message
          if (axiosError?.response?.status === 403) {
            navigate(`/403?message=${axiosError?.response?.data?.message}`, {})
          }
        }
        throw error
      }
    },

    staleTime: 1000 * 60, // 1 minute
    placeholderData: keepPreviousData,
    enabled: path && enabled,
    refetchOnMount: 'always'
  })

  const fetch = async (anotherPath = null) => {
    if (anotherPath && anotherPath !== path) {
      // setPathState(path)
    }
    await refetch()
  }

  const invalidate = async () => {
    await queryClient.invalidateQueries({
      queryKey: [path]
    })
  }

  const {
    mutate: optimisticUpdate,
    variables: optimisticVariables,
    isPending: optimisticPending
  } = useMutation({
    mutationFn: ({ data, link }: { data: any; link: string }) => axios.post(linkGenerator(link)),
    onSettled: async () => {
      return await invalidate()
    },
    onError: () => {
      showError('Something went wrong', 'top-center')
    }
  })

  return {
    items: data?.result?.data ? data.result.data : [],
    item: data?.result?.data ? (data.result.data as ItemType) : null,
    result: data?.result ? (data.result as any) : [],
    loading: isPending,
    error: error as any,
    options,
    totalPages: data?.result?.meta?.last_page ?? 0,
    totalItems: data?.result?.meta?.total ?? 0,
    showedItems: data?.result?.meta?.to ?? 0,
    fetch,
    openDelete,
    closeDelete,
    deleteItem,
    pagenate,
    search,
    filter,
    resetFilter,
    sort,
    deleteId,
    isFetching,
    invalidate,
    optimisticUpdate,
    optimisticVariables: optimisticVariables?.data || {},
    optimisticPending
  }
}

export default FetchHock

function parseParams(params) {
  let options = ''
  Object.entries(params).forEach(([key, value]) => {
    if (key === 'filter') {
      Object.entries(value).forEach(([key, value]) => {
        if (key === 'created_at') {
          return Object.entries(value).forEach(([nestedKey, value]) => {
            options += `filter[${key}][${nestedKey}]=${value}&`
          })
        }
        if (Array.isArray(value)) {
          value.forEach((element) => {
            options += `filter[${key}][]=${element}&`
          })
        } else {
          options += `filter[${key}]=${value}&`
        }
      })
    } else {
      options += `${key}=${value}&`
    }
  })

  return options
}
