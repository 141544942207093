import { useEffect, useState } from 'react'
import { DefaultValues, FieldValues, useForm } from 'react-hook-form'

import linkGenerator from './linkGenerator'
import axios from 'axios'
import { DevTool } from '@hookform/devtools'

import showError from './ErorrNotfication'

import { useTranslation } from 'react-i18next'

type response = {
  status: boolean
  response?: any
}

const UpdateHock = <formType extends FieldValues>({
  path,
  initValue,
  editId = null,
  allowGet = true,
  editResponseBeforeRegister
}: {
  path?: string
  initValue?: formType
  editId?: number | null
  allowGet?: boolean
  editResponseBeforeRegister?: (data: any) => any
}) => {
  const { t } = useTranslation()
  const formMethods = useForm<formType>({ defaultValues: initValue as DefaultValues<formType> })

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    getValues,
    control,
    watch
  } = formMethods
  const [loading, setLoading] = useState<boolean>(false)
  const [getLoading, setGetLoading] = useState<boolean>(false)
  const [uploading, setUploading] = useState<boolean>(false)

  const update = async (item: formType): Promise<response> => {
    if (uploading) {
      showError(t('Please wait until uploading finish'))
      return {
        status: false
      }
    }

    setLoading(true)

    try {
      const response = await axios.put(linkGenerator(`${path}${editId ? '/' + editId : ''}`), item)

      setLoading(false)
      return {
        status: true,
        response: response.data
      }
    } catch (error: any) {
      setLoading(false)
      if (error.code === 'ERR_NETWORK') {
        showError(t('Netwerk error you have error in your network'))
      }
      // if (error.response.data.status === 'error') {
      //   for (const key in error.response.data.data) showError(error.response.data.data[key][0])
      // }
      // if (error.response?.status === 422 || error.response?.status === 400) {
      //   for (const key in error.response.data.errors) showError(error.response.data.errors[key][0])
      // }
      if (
        error.response?.status === 422 ||
        error.response?.status === 400 ||
        error.response?.status === 403
      ) {
        const errorObject = error?.response?.data?.data ?? error?.response?.data?.errors
        if (errorObject !== undefined) {
          for (const key in errorObject) {
            const errorArr = errorObject[key] || []
            if (errorArr?.length > 0) {
              showError(errorArr[0])
            }
          }
        } else showError(error.response.data.message)
      }

      return {
        status: false
      }
    }
  }

  const get = async (): Promise<void> => {
    setGetLoading(true)
    try {
      const response = await axios.get(linkGenerator(`${path}${editId ? '/' + editId : ''}/edit`))

      const formattedResponse = editResponseBeforeRegister
        ? editResponseBeforeRegister(response.data.result)
        : response.data.result
      reset(formattedResponse)
    } catch (error: any) {
      if (error.response?.status === 500) {
        showError('Error while getting data')
      }
      if (error.code === 'ERR_NETWORK') {
        showError(t('Netwerk error you have error in your network'))
      }

      if (
        error.response?.status === 422 ||
        error.response?.status === 400 ||
        error.response?.status === 403
      ) {
        const errorObject = error?.response?.data?.data ?? error?.response?.data?.errors
        if (errorObject !== undefined) {
          for (const key in errorObject) {
            const errorArr = errorObject[key] || []
            if (errorArr?.length > 0) {
              showError(errorArr[0])
            }
          }
        } else showError(error.response.data.message)
      }

      // if (error.response?.status === 422) {
      //   for (const key in error.response.data.errors) showError(error.response.data.errors[key][0])
      // }
    } finally {
      setGetLoading(false)
    }
  }

  useEffect(() => {
    if (allowGet) {
      get()
    }
  }, [allowGet])

  const openUploading = () => setUploading(true)
  const closeUploading = () => setUploading(false)

  return {
    loading,
    getLoading,
    register,
    handleSubmit,
    errors,
    update,
    get,
    openUploading,
    closeUploading,
    setValue,
    getValues,
    control,
    watch,
    reset,
    formMethods,
    DevTool: () => <DevTool control={control} />
  }
}

export default UpdateHock
