import { ImageUpload, Input, Select, Submit, Switch, TextEditor, Textarea } from 'Components/Form'
import { Items } from '../models/types'
import { useTranslation } from 'react-i18next'
import UpdateHock from 'helpers/UpdateHock'
import { Button } from 'reactstrap'
import Empty from 'Components/IndexTable/partials/Empty'
import showSuccess from 'helpers/SuccessNotfication'
import { removeEmptyValues, transformDataBeforeSubmit, transformToKeyValue } from '../helper/helper'

function DynamicSettingsForm({ fields }: { fields: Items[] }) {
  const { t } = useTranslation()
  const { register, handleSubmit, errors, loading, getLoading, update } = UpdateHock<any>({
    path: '/settings',
    initValue: transformToKeyValue(fields),
    allowGet: false
  })

  const handle = async (data: any) => {
    console.log(data)
    const dataWithoutNulls = removeEmptyValues(data)
    if (!dataWithoutNulls) return
    const dataWithoutNullsTransformed = transformDataBeforeSubmit(dataWithoutNulls)
    console.log(dataWithoutNullsTransformed)
    const response = await update(dataWithoutNullsTransformed)
    if (response.status) {
      showSuccess(t('Settings updated successfully'))
    }
  }
  if (fields.length === 0) return <Empty />

  const FormInputsMap: any = {
    text: Input,
    textarea: Textarea,
    boolean: Switch,
    select: Select,
    file: ImageUpload
  }
  return (
    <form onSubmit={handleSubmit(handle)}>
      {fields?.map((item, index) => {
        const { type, key, required } = item
        const ComponentType = FormInputsMap[type] || Input
        return (
          <div key={index}>
            <ComponentType
              horizontal
              type={type === 'double' ? 'number' : type}
              step={type === 'double' ? 0.01 : 1}
              label={t(key)}
              //   placeholder={t(`Enter ${key}`)}
              model={register(key, {
                required: required === 1 ? t(key) + ' ' + t('is required') : false,
                valueAsNumber: type === 'double' || type === 'number'
              })}
              error={errors[key]}
              options={item?.options}
              value={item?.value}
            />
          </div>
        )
      })}

      <Submit loading={loading} label={t('Update')} />
    </form>
  )
}

export default DynamicSettingsForm
