import { Button, Col, Container, Row } from 'reactstrap'
import { useTranslation } from 'react-i18next'
import { Input, Submit, Select, ImageUpload, Search } from 'Components/Form'
import CreateHook from 'helpers/CreateHook'
import PopUp from 'Components/PopUp'
import { country } from 'common/data'

const CreateModal = ({ close, show, onSuccess }: any) => {
  const { t } = useTranslation()

  type moderatortype = {
    id?: number
    name: string
    lastname: string
    email: string
    password: string
    role_id: number | null
    image?: string | null
    dialing_code: string
    phone: number | null
  }

  // prepare hock
  const { register, handleSubmit, errors, create, loading, closeUploading, openUploading } =
    CreateHook<moderatortype>('/moderators', {
      name: '',
      lastname: '',
      email: '',
      password: '',
      role_id: null,
      image: null,
      dialing_code: '',
      phone: null
    })

  const submit = async (data: moderatortype) => {
    const response = await create(data)
    if (response.status) {
      close()
      onSuccess()
    }
  }

  return (
    <PopUp title={t('Create New Moderator')} show={show} close={close}>
      <form onSubmit={handleSubmit(submit)}>
        <Container fluid>
          <Row>
            <Col md={12}>
              <ImageUpload
                label={t('Image')}
                model={register('image')}
                error={errors.image}
                onUpload={openUploading}
                onFininishUpload={closeUploading}
              />
            </Col>
            <Col md={6}>
              <Input
                label={t('First Name')}
                placeholder={t('Enter first name')}
                model={register('name', {
                  required: t('First name is required'),
                  minLength: {
                    value: 3,
                    message: t('First name must be bigger than or equal 3')
                  }
                })}
                error={errors.name}
              />
            </Col>
            <Col md={6}>
              <Input
                label={t('Last Name')}
                placeholder={t('Enter last name')}
                model={register('lastname', {
                  required: t('Last name is required'),
                  minLength: {
                    value: 3,
                    message: t('Last name must be bigger than or equal 3')
                  }
                })}
                error={errors.lastname}
              />
            </Col>
            <Col md={3}>
              <Select
                label={t('Code')}
                placeholder={t('Code')}
                model={register('dialing_code', {
                  required: t('code is required')
                })}
                error={errors.dialing_code}
                options={country.map((item: any) => {
                  return { label: item.countryCode, value: item.countryCode }
                })}
              />
            </Col>
            <Col md={9}>
              <Input
                label={t('Phone')}
                type="number"
                model={register('phone', {
                  required: t('Phone is required'),
                  minLength: {
                    value: 3,
                    message: t('Phone must be bigger than or equal 8')
                  }
                })}
                error={errors.phone}
                placeholder={t('Enter phone')}
              />
            </Col>
            <Col md={12}>
              <Input
                label={t('Email')}
                type="email"
                placeholder={t('Enter email')}
                model={register('email', {
                  required: t('Email is required'),
                  minLength: {
                    value: 3,
                    message: t('Email must be bigger than or equal 3')
                  }
                })}
                error={errors.email}
              />
            </Col>
            <Col md={12}>
              <Input
                label={t('Password')}
                placeholder={t('Enter password')}
                model={register('password', {
                  required: t('Password is required'),
                  minLength: {
                    value: 3,
                    message: t('Password must be bigger than or equal 3')
                  }
                })}
                error={errors.password}
              />
            </Col>
            <Col md={12}>
              <Search
                label={t('Role')}
                placeholder={t('Enter role')}
                model={register('role_id', {
                  required: t('Role is required')
                })}
                error={errors.role_id}
                path="role"
              />
            </Col>
          </Row>
        </Container>

        <div className="hstack gap-2 justify-content-end">
          <Button className="btn btn-link btn-secondary fw-medium" outline onClick={close}>
            <i className="ri-close-line me-1 align-middle"></i> {t('Close')}
          </Button>
          <Submit loading={loading} label={t('Create')} />
        </div>
      </form>
    </PopUp>
  )
}
export default CreateModal
