import { Button, Col, Container, Row } from 'reactstrap'
import { useTranslation } from 'react-i18next'
import { Input, Submit, ImageUpload, Textarea, Search } from 'Components/Form'
import CreateHook from 'helpers/CreateHook'
import PopUp from 'Components/PopUp'

const CreateModal = ({
  close,
  show,
  onSuccess
}: {
  close: () => void
  show: boolean
  onSuccess: () => void
}) => {
  const { t } = useTranslation()

  type bannertype = {
    image: string | null
    title: string
    description: string | null
    parent_id: number | null
  }

  // prepare hock
  const { register, handleSubmit, errors, create, loading, closeUploading, openUploading } =
    CreateHook<bannertype>('/category', {
      image: null,
      title: '',
      description: '',
      parent_id: null
    })

  const submit = async (data: bannertype) => {
    const response = await create(data)
    if (response.status) {
      close()
      onSuccess()
    }
  }

  return (
    <PopUp title={t('Create Category')} show={show} close={close}>
      <form onSubmit={handleSubmit(submit)}>
        <Container fluid>
          <Row>
            <Col md={12}>
              <ImageUpload
                label={t('Image')}
                model={register('image', {
                  required: t('Image is required')
                })}
                error={errors.image}
                onUpload={openUploading}
                onFininishUpload={closeUploading}
              />
            </Col>
            <Col md={12}>
              <Input
                label={t('Title')}
                type="text"
                placeholder={t('Enter title')}
                model={register('title', {
                  required: t('Title is required'),
                  minLength: {
                    value: 3,
                    message: t('Title must be bigger than or equal 3')
                  }
                })}
                error={errors.title}
              />
            </Col>
            <Col md={12}>
              <Textarea
                label={t('Description')}
                placeholder={t('Enter description')}
                model={register('description')}
                error={errors.description}
              />
            </Col>
            <Col md={12}>
              <Search
                label={t('Parent')}
                placeholder={t('Enter Parent')}
                model={register('parent_id', {})}
                propTitle="title"
                error={errors.parent_id}
                path="category"
              />
            </Col>
          </Row>
        </Container>

        <div className="hstack gap-2 justify-content-end">
          <Button className="btn btn-link btn-secondary fw-medium" outline onClick={close}>
            <i className="ri-close-line me-1 align-middle"></i> {t('Close')}
          </Button>
          <Submit loading={loading} label={t('Create')} />
        </div>
      </form>
    </PopUp>
  )
}
export default CreateModal
