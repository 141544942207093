import { ImageUpload, Input, Radio } from 'Components/Form'
import UpdateHock from 'helpers/UpdateHock'

import { useTranslation } from 'react-i18next'
import { Col, Form, Row } from 'reactstrap'

import DatePicker from 'Components/Form/DatePicker'
import { useQueryClient } from '@tanstack/react-query'

function ProfileForm({ profile }: any) {
  const { t } = useTranslation()

  const queryClient = useQueryClient()

  const {
    register,
    update,
    watch,
    handleSubmit,
    errors,
    openUploading,
    closeUploading,
    getValues
  } = UpdateHock<any>({
    path: '/profile',
    initValue: {
      name: profile?.name,
      lastname: profile?.lastname,
      dialing_code: profile?.dialing_code,
      phone: profile?.phone,
      email: profile?.email,
      gender: profile?.gender,
      birthdate: profile?.birthdate,
      image: profile?.image
    },
    allowGet: false
  })

  const submit = async (data: any) => {
    const response = await update(data)
    if (response.status) {
      queryClient.invalidateQueries({
        queryKey: ['/profile']
      })
    }
  }

  return (
    <Form onSubmit={handleSubmit(submit)}>
      <Row>
        <Col lg={6}>
          <div className="mb-3">
            <Input
              label={t('Name')}
              type="text"
              placeholder="Enter your firstname"
              model={register('name', {
                required: t('Name is required')
              })}
              error={errors.name}
            />
          </div>
        </Col>
        <Col lg={6}>
          <div className="mb-3">
            <Input
              label={t('Last Name')}
              type="text"
              placeholder="Enter your lastname"
              model={register('lastname', {
                required: t('Last name is required')
              })}
              error={errors.lastname}
            />
          </div>
        </Col>
        <Col lg={6}>
          <div className="mb-3">
            <Input
              label={t('Email')}
              type="text"
              placeholder="Enter your email"
              model={register('email', {
                required: t('Email is required')
              })}
              error={errors.email}
            />
          </div>
        </Col>
        <Col lg={6}>
          <div className="mb-3">
            <DatePicker
              label={t('Birthdate')}
              placeholder={`${t('Enter')} ${t('Birthdate')}`}
              model={register('birthdate', {
                required: t('Birthdate is required')
              })}
              error={errors.birthdate}
              defaultValue={getValues('birthdate') && new Date(getValues('birthdate'))}
            />
          </div>
        </Col>
        <Col lg={6}>
          <div className="mb-3">
            <Input
              label={t('Dialing code')}
              type="text"
              placeholder="Enter your dialing code"
              model={register('dialing_code', {
                required: t('Dialing code is required')
              })}
              error={errors.dialing_code}
            />
          </div>
        </Col>
        <Col lg={6}>
          <div className="mb-3">
            <Input
              label={t('Phone number')}
              type="text"
              placeholder="Enter your phone number"
              model={register('phone', {
                required: t('Phone number is required')
              })}
              error={errors.phone}
            />
          </div>
        </Col>

        <Col lg={4} md={6}>
          <div>
            <p className="  fw-medium">{t('Gender')}</p>
            <div className="form-check mb-2">
              <Radio
                value="male"
                label={t('Male')}
                model={register('gender', {
                  required: t('Gender is required')
                })}
              />
            </div>
            <div className="form-check">
              <Radio
                value="female"
                label={t('Female')}
                model={register('gender', {
                  required: t('Gender is required')
                })}
                error={errors.gender}
              />
            </div>
          </div>
        </Col>
        <Col lg={12}>
          <div className="mb-3">
            <ImageUpload
              label={t('Image')}
              model={register('image', {
                required: t('Image is required')
              })}
              error={errors.image}
              onUpload={openUploading}
              onFininishUpload={closeUploading}
              value={getValues('image')}
            />
          </div>
        </Col>
        <Col lg={12}>
          <div className="hstack gap-2 justify-content-end">
            <button type="submit" className="btn btn-primary">
              {t('Update')}
            </button>
          </div>
        </Col>
      </Row>
    </Form>
  )
}

export default ProfileForm
