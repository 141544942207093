import Config from 'ThemeManager/types/Config'
import placeholder from './placeholder.png'
import formula from './Formula'
import DefaultValue from './DefaultValue'

const config: Config = {
  title: 'Dynamic Slider section',
  placeholder: placeholder,
  form: formula,
  default: DefaultValue
}

export default config
