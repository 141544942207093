import { Container, Row, Col, Label, Input } from 'reactstrap'
import { useTranslation } from 'react-i18next'
import BreadCrumb from 'Components/Common/BreadCrumb'
import FetchHook from 'helpers/FetchHock'
import ProfilePhotoCard from './ProfilePhotoCard'
import ProfileInfoTabs from './ProfileInfoTabs'

type Profile = {
  id: number
  name: string
  last_name: string
  email: string
  dialing_code: string
  phone: string
  gender: 'male' | 'female' // Assuming gender will be either 'male' or 'female'
  birthdate: string | null // Can be a string (date) or null
  is_active: boolean | number // Can be either a boolean or a number
  role: any[] // Assuming role is an array, type this more specifically if needed
  image: string
  mobile_image: string
  accessToken: string | null
}
const UserProfile = () => {
  document.title = 'Profile | Sallatk'
  const { t } = useTranslation()

  const {
    result: profile,
    loading,
    error
  } = FetchHook<Profile>({
    path: '/profile'
  })

  if (loading) {
    return <div>Loading...</div>
  }
  console.log('profile', profile)
  return (
    <div className="page-content">
      <Container fluid>
        <BreadCrumb title={t('Profile')} pageTitle={t('Home')} />
        <div className="position-relative mx-n4 mt-n4">
          <div className="profile-wid-bg profile-setting-img">
            {/*<img src={progileBg} className="profile-wid-img" alt=""/>*/}
            <div className="overlay-content">
              <div className="text-end p-3">
                <div className="p-0 ms-auto rounded-circle profile-photo-edit">
                  <Input
                    id="profile-foreground-img-file-input"
                    type="file"
                    className="profile-foreground-img-file-input"
                  />
                  <Label
                    htmlFor="profile-foreground-img-file-input"
                    className="profile-photo-edit btn btn-light">
                    <i className="ri-image-edit-line align-bottom me-1"></i> Change Cover
                  </Label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Row>
          <Col xxl={3}>
            <ProfilePhotoCard profile={profile} />
          </Col>

          <Col xxl={9}>
            <ProfileInfoTabs profile={profile} />
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default UserProfile
