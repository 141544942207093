import fetch from './server'
import axios from 'axios'
import { setUser, removeUse } from 'slices/user/thunk'
// notfications helper
import showError from './ErorrNotfication'
import i18n from 'i18n'
import linkGenerator from './linkGenerator'

interface userData {
  id: number
  store_id: number
  name: string
  last_name: string
  email: string
  phone: string
  balance: string
  gender: string
  status: number
  store: {
    id: number
    url: string
    name: string
  }
  is_store_owner: boolean
  online_at: string
  accessToken: string
  default_setting: boolean
  is_package_expired: boolean
  dialing_code: string
  role: any[]
  is_active: number
  settings: {
    default_language: {
      id: number
      code: string
      flag: string
      title: string
      laravel_through_key: number
    }
    default_currency: {
      id: number
      code: string
      flag: string
      laravel_through_key: number
    }
  }
}

export const login = async (user: any) => {
  // send data to server
  const response = await fetch<userData>('/login', user, 'post')
  // if success
  if (response.status && response.data) {
    // save user to localstorage
    localStorage.setItem('authUser', JSON.stringify(response.data))
    // assign data to axios
    axios.defaults.headers.common['authorization'] = `Bearer ${response.data.accessToken}`
    // set user data
    setUser(response.data)
  } else {
    for (const key in response.errors) showError(response.errors[key])
  }
  return response
}

export const removeSession = async () => {
  localStorage.removeItem('authUser')
  removeUse()
}

export const logout = async () => {
  try {
    const response = await axios.post(linkGenerator('/logout'))
    const status = response?.data?.status || null
    if (status === 'success') {
      // remove user from localstorage
      removeSession()
      return true
    } else {
      return false
    }
  } catch (error) {
    return false
  }
}

export const getUserData = (): userData | undefined => {
  const userData = localStorage.getItem('authUser')
  if (userData) {
    // parse json data
    const parsedUserData = JSON.parse(userData) as userData
    // set user data
    setUser(parsedUserData)
    return parsedUserData
  } else {
    return undefined
  }
}
export const editUserData = async (updatedFields: Partial<userData>) => {
  const currentUserData = localStorage.getItem('authUser')

  if (currentUserData) {
    const parsedUserData = JSON.parse(currentUserData) as userData
    const updatedUserData = { ...parsedUserData, ...updatedFields }
    localStorage.setItem('authUser', JSON.stringify(updatedUserData))
    setUser(updatedUserData)
    return updatedUserData
  } else {
    console.log('User data not found in local storage')
    return null
  }
}

export const setAuthorization = (token: string) => {
  axios.defaults.headers.common['Authorization'] = 'Bearer ' + token
  axios.defaults.headers.common['Accept-Language'] =
    i18n?.language || localStorage?.getItem('I18N_LANGUAGE') || 'en'

  // handle 401 error redirect to login
  axios.interceptors.response.use(
    (response) => {
      return response
    },
    async (error) => {
      if (
        error?.response?.status === 401 ||
        error?.response?.data?.message === 'Unauthenticated.'
      ) {
        removeSession()
        window.location.href = '/login'
      }
      return Promise.reject(error)
    }
  )
}
