import fetch from 'helpers/server'
import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import {
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  Spinner,
  UncontrolledDropdown
} from 'reactstrap'
import UpdateModal from '../components/UpdateModal'
import FetchHock from 'helpers/FetchHock'
import Error from 'Components/IndexTable/partials/Error'
import { EditTranslation } from '../models/details'
import IndexTable from 'Components/IndexTable'
import { useTranslation } from 'react-i18next'
import BreadCrumb from 'Components/Common/BreadCrumb'

function TranslationsEdit() {
  const { t } = useTranslation()
  const params = useParams()
  const fetchHock: any = FetchHock<any, { translated: string }>({
    path: `/translations/${params.key}/edit/${params.code}`
  })

  const [data, setData] = useState<any>(null)
  const [refresh, setRefresh] = useState<boolean>(false)
  const refreshReport = () => setRefresh(!refresh)

  // create modal logic
  const [showUpdate, changeShowUpdate] = useState<boolean>(false)
  const openUpdate = (data: any) => {
    setData(data)
    changeShowUpdate(true)
  }
  const closeUpdate = () => {
    setData(null)
    changeShowUpdate(false)
  }

  const headerColumns: any = [
    {
      title: 'Id',
      accessor: 'id'
    },
    {
      title: 'Title'
    },
    {
      title: 'Translation'
    },

    {
      title: 'Action'
    }
  ]

  const filterItems: any = [
    {
      type: 'search',
      col: 6,
      placeholder: t('Search for Item'),
      action: (value: string) => fetchHock.search(value)
    },
    {
      type: 'select',
      col: 6,
      placeholder: t('Select status'),
      options: [
        { label: t('translated'), value: 1 },
        { label: t('untranslated'), value: 0 }
      ],
      action: (value: boolean) => fetchHock.filter('translated', value)
    }
  ]
  // @ts-ignore
  const formFields = fetchHock?.items?.form || []

  return (
    <div className="page-content">
      <Container fluid>
        <BreadCrumb />

        <Row>
          <Col lg={12}>
            <IndexTable
              title={t('Translations')}
              headerColumns={headerColumns}
              filters={filterItems}
              {...fetchHock}>
              {/* @ts-ignore */}
              {fetchHock.items.items?.map((item: any, index: number) => {
                const titleKey = formFields[0]?.column || null
                if (!titleKey) {
                  console.log('no title key')
                  return null
                }

                const defaultKey = `default_${titleKey}`
                const targetKey = `target_${titleKey}`

                const defaultValue = item[defaultKey]
                const targetValue = item[targetKey]
                return (
                  <tr key={index}>
                    <td>
                      <div className="d-flex align-items-center">
                        <div className="flex-grow-1">
                          <h5 className="fs-14 mb-1">#{item.id} </h5>
                        </div>
                      </div>
                    </td>
                    <td>
                      <p className="text-muted mb-1">{defaultValue}</p>
                    </td>
                    <td>
                      <p className="text-muted mb-1">{targetValue ?? <MissingTranslation />}</p>
                    </td>
                    <td>
                      <div className="hstack gap-3 flex-wrap">
                        <UncontrolledDropdown>
                          <DropdownToggle
                            href="#"
                            className="btn btn-secondary btn-sm"
                            tag="button">
                            <i className="ri-more-fill" />
                          </DropdownToggle>
                          <DropdownMenu container={'body'} className="dropdown-menu-end">
                            <DropdownItem
                              onClick={() =>
                                openUpdate({ formValues: item, formFields: formFields })
                              }>
                              <i className="ri-eye-line me-2"></i>
                              {t('Edit')}
                            </DropdownItem>
                          </DropdownMenu>
                        </UncontrolledDropdown>
                      </div>
                    </td>
                  </tr>
                )
              })}
            </IndexTable>
          </Col>
        </Row>
      </Container>
      {showUpdate && (
        <UpdateModal
          close={closeUpdate}
          show={showUpdate}
          modalData={data}
          onSuccess={() => {
            refreshReport()
            fetchHock.fetch()
          }}
        />
      )}
    </div>
  )
}

export default TranslationsEdit

function MissingTranslation() {
  return <i className="las la-exclamation-triangle text-danger"></i>
}
