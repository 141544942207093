import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router'

const AccessDenied = () => {
  const { t } = useTranslation()
  const location = useLocation()

  document.title = 'Access denied | Sallatk'
  const queryParams = new URLSearchParams(location.search)
  const message = queryParams.get('message') || t('Access denied. You do not have permission.')

  return (
    <div
      className="page-content "
      style={{
        minHeight: 'calc(100vh - 123px)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column'
      }}>
      {/* <i className="ri-error-warning-line display-5 text-danger"></i>
      <i className="ri-time-line display-5 text-danger"></i> */}
      <i className="ri-alarm-warning-line display-5 text-danger"></i>
      <div className="mt-2 pt-2 fs-15 mx-4 mx-sm-5">
        <h4>{message}</h4>
      </div>
    </div>
  )
}

export default AccessDenied
