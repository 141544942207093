import { useTranslation } from 'react-i18next'
import {
  ButtonGroup,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown
} from 'reactstrap'
import { Link } from 'react-router-dom'
import clsx from 'clsx'

function AddProductsDropdownButton({ btnClassName }: { btnClassName?: string }) {
  const { t } = useTranslation()

  const links = [
    {
      title: t('Regular product'),
      route: '/products/create/default'
    },
    {
      title: t('Product with features'),
      route: '/products/create/withFeatures'
    },
    {
      title: t('Product with paid features'),
      route: '/products/create/WithPaidFeatures'
    },
    {
      title: t('Digital card'),
      route: '/products/create/card'
    },
    {
      title: t('Assembled product'),
      route: '/products/create/multi'
    },
    {
      title: t('Donation product'),
      route: '/products/create/donation'
    },
    {
      title: t('Course product'),
      route: '/products/create/course'
    }
  ]
  return (
    <ButtonGroup
      style={{
        width: 'fit-content'
      }}>
      <UncontrolledDropdown>
        <DropdownToggle tag="button" className={clsx(btnClassName || 'btn btn-secondary add-btn')}>
          <i className="ri-add-circle-line align-middle me-1"></i> {t('Add new product')}
        </DropdownToggle>
        <DropdownMenu>
          {links.map((el, index) => (
            <DropdownItem key={index} tag={Link} to={el.route}>
              {el.title}
            </DropdownItem>
          ))}
        </DropdownMenu>
      </UncontrolledDropdown>
    </ButtonGroup>
  )
}

export default AddProductsDropdownButton
