// botstrap elemnts
import { Button, Col, Container, Row } from 'reactstrap'
// translation
import { useTranslation } from 'react-i18next'
// page elements
import { Input, Submit, Select, Search, Textarea, FileUpload } from 'Components/Form'
import CreateHook from 'helpers/CreateHook'
import PopUp from 'Components/PopUp'

const CreateModal = ({ close, show, onSuccess }: any) => {
  const { t } = useTranslation()

  type tickettype = {
    id?: number
    subject: string
    content: string
    priority: string
    site_ticket_category_id: number
    attachment: string
  }

  // prepare hock
  const { register, handleSubmit, errors, create, loading, closeUploading, openUploading } =
    CreateHook<tickettype>('/tickets', {
      subject: '',
      content: '',
      priority: '',
      site_ticket_category_id: 0,
      attachment: ''
    })

  const submit = async (data: tickettype) => {
    const response = await create(data)
    if (response.status) {
      close()
      onSuccess()
    }
  }

  return (
    <PopUp title={t('Create new ticket')} show={show} close={close}>
      <form onSubmit={handleSubmit(submit)}>
        <Container fluid>
          <Row>
            <Col md={12}>
              <Input
                label={t('Subject')}
                placeholder={t('Enter subject')}
                model={register('subject', {
                  required: t('Subject is required'),
                  minLength: {
                    value: 3,
                    message: t('Subject must be bigger than or equal 3')
                  }
                })}
                error={errors.subject}
              />
            </Col>
            <Col md={12}>
              <Textarea
                label={t('Content')}
                placeholder={t('Enter content')}
                model={register('content', {
                  required: t('Content is required'),
                  minLength: {
                    value: 3,
                    message: t('Content must be bigger than or equal 3')
                  }
                })}
                error={errors.content}
              />
            </Col>
            <Col md={12}>
              <Select
                label={t('Priority')}
                placeholder={t('Priority')}
                model={register('priority', {
                  required: t('Priority is required')
                })}
                error={errors.priority}
                options={[
                  { label: t('Low'), value: 'Low' },
                  { label: t('Medium'), value: 'Medium' },
                  { label: t('High'), value: 'High' }
                ]}
              />
            </Col>
            <Col md={12}>
              <Search
                label={t('Category')}
                placeholder={t('Enter category')}
                model={register('site_ticket_category_id', {
                  required: t('Category is required')
                })}
                error={errors.site_ticket_category_id}
                path="ticket_category"
              />
            </Col>
            <Col md={12}>
              <FileUpload
                label={t('Attachment')}
                placeholder={t('Select file')}
                model={register('attachment')}
                error={errors.attachment}
                onUpload={openUploading}
                onFininishUpload={closeUploading}
              />
            </Col>
          </Row>
        </Container>

        <div className="hstack gap-2 justify-content-end">
          <Button className="btn btn-link btn-secondary fw-medium" outline onClick={close}>
            <i className="ri-close-line me-1 align-middle"></i> {t('Close')}
          </Button>
          <Submit loading={loading} label={t('Create')} />
        </div>
      </form>
    </PopUp>
  )
}
export default CreateModal
