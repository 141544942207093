import React from 'react'
import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'

// Define a prop interface
interface ImageWithFallbackProps {
  src: string
  alt?: string
  className?: string
  style?: any
  defaultSrc?: string
  height?: number
}

const ImageWithFallback = ({
  src,
  alt,
  defaultSrc,
  style,
  className,
  height
}: ImageWithFallbackProps) => {
  const [imageError, setImageError] = React.useState(src ? false : true)
  const defaultImage = defaultSrc ?? '/img-error.png'
  if (!src || imageError) {
    return <img src={defaultImage} alt={alt} className={className} style={style} height={height} />
  }
  return (
    <Zoom
      IconZoom={() => {
        return <i className="ri-zoom-in-line fs-16"></i>
      }}
      IconUnzoom={() => {
        return <i className="ri-zoom-out-line fs-16 "></i>
      }}>
      <img
        src={imageError ? defaultImage : src}
        alt={alt}
        className={className}
        style={style}
        height={height}
        onError={() => setImageError(true)}
      />
    </Zoom>
  )
}

export default ImageWithFallback
