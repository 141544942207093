import { useTranslation } from 'react-i18next'

import { Card, CardBody, CardHeader } from 'reactstrap'
import { ImageUpload, MultiImageUpload } from 'Components/Form'
import { useFormContext } from 'react-hook-form'

const Gallery = ({
  openUploading,
  closeUploading
}: {
  openUploading: any
  closeUploading: any
}) => {
  const { t } = useTranslation()
  const {
    register,
    formState: { errors },
    getValues
  } = useFormContext()

  return (
    <Card>
      <CardHeader className="">
        <h5 className="card-title mb-0">{t('Product Gallery')}</h5>
      </CardHeader>
      <CardBody>
        <ImageUpload
          label={t('Main image')}
          model={register('main_image', {
            required: t('Main image is required')
          })}
          error={errors.main_image}
          onUpload={openUploading}
          onFininishUpload={closeUploading}
          value={getValues('web_main_image')}
        />
        <MultiImageUpload
          label={t('Product Gallery')}
          placeholder={t('Drop files here or click to upload')}
          model={register('images')}
          error={errors.images}
          onUpload={openUploading}
          value={getValues('images')}
          images={getValues('web_images')}
          onFininishUpload={closeUploading}
        />
      </CardBody>
    </Card>
  )
}

export default Gallery
