import { Container } from 'reactstrap'
import BreadCrumb from 'Components/Common/BreadCrumb'
import { useTranslation } from 'react-i18next'
import Header from './components/Header'
import ItemsCardsView from './components/CardView'
import ItemsTablesView from './components/TableView'

import FetchHock from 'helpers/FetchHock'
import { Filter } from './filterType'

import { useState } from 'react'
import Loader from 'Components/Common/Loader'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { setShowTypeAction } from 'slices/products/reducer'
import clsx from 'clsx'
import { ProductProvider } from './Context'
import Error from 'Components/IndexTable/partials/Error'

const ProductsList = () => {
  document.title = 'Products | Sallatk'

  const { t } = useTranslation()

  const [showFilter, setShowFilter] = useState<boolean>(false)
  const openFilter = () => setShowFilter(true)
  const closeFilter = () => setShowFilter(false)

  const dispatch = useDispatch()
  const showType = useSelector((state: any) => state.products.showType)

  const handleShowTypeChange = (type: any) => {
    dispatch(setShowTypeAction(type))
  }

  interface products {
    id: number
    title?: string
    slug?: string
    hidden: boolean
    product_type?: {
      id: number
      title?: string
    }
    web_main_image?: string
    stock: number
    price: number
    ordered: number
    ratings?: string
    created_date?: string
    created_time?: string
    product_course_id: number
  }

  interface filter {
    hidden: string
  }

  const products = FetchHock<products, filter>({
    path: '/products',
    initFilter: {
      hidden: ''
    },
    limit: 12
  })
  const filters = FetchHock<any, Filter>({
    path: '/products/getFilters'
  })

  if (products?.loading || filters?.loading) {
    return (
      <div className="vh-100 d-flex align-items-center justify-content-center">
        <Loader />
      </div>
    )
  }

  if (products?.error) {
    return (
      <div className="page-content">
        <Error message={products?.error?.message} />
      </div>
    )
  }

  if (filters?.result) {
    return (
      <div
        className={clsx('page-content', {
          // 'opacity-50': products.isFetching
        })}>
        <Container fluid>
          <BreadCrumb />
          <Header showType={showType} setShowType={handleShowTypeChange} openFilter={openFilter} />
          {showType == 'card' && (
            <ProductProvider products={products} filters={filters?.result}>
              <ItemsCardsView showFilter={showFilter} closeFilter={closeFilter} />
            </ProductProvider>
          )}
          {showType == 'table' && (
            <ProductProvider products={products} filters={filters?.result}>
              <ItemsTablesView />
            </ProductProvider>
          )}
        </Container>
      </div>
    )
  }
}

export default ProductsList
