import { useTranslation } from 'react-i18next'
import { Button, Col, Container, Row } from 'reactstrap'
import { Input, Submit, ImageUpload, Switch } from 'Components/Form'
import UpdateHock from 'helpers/UpdateHock'
import PopUp from 'Components/PopUp'
import IBANInput from './IBANInput'

const UpdateModal = ({ show, close, onSuccess, itemId }: any) => {
  const { t } = useTranslation()

  // prepare hock
  const {
    register,
    handleSubmit,
    errors,
    loading,
    closeUploading,
    openUploading,
    update,
    getValues,
    getLoading,
    DevTool
  } = UpdateHock<{
    title: string
    code: string
    status: number
    image_url?: string
    image_web?: string
    company_id?: number
    company?: {
      id: number
      code: string
      name: string
    }
  }>({
    path: '/offline-payment',
    initValue: {
      code: '',
      status: null,
      image_url: '',
      title: ''
    },
    editId: itemId
  })

  const submit = async (data) => {
    const response = await update(data)
    if (response.status) {
      close()
      onSuccess()
    }
  }
  return (
    <PopUp title={t('Update Payment Method')} show={show} close={close} loading={getLoading}>
      <form onSubmit={handleSubmit(submit)}>
        <DevTool />
        <Container fluid>
          <Row>
            <Col md={12}>
              <Input
                label={t('Title')}
                placeholder={t('Enter') + ' ' + t('Title')}
                model={register('title', {
                  required: t('Title is required')
                })}
                error={errors.title}
              />
            </Col>

            <Col md={12}>
              <ImageUpload
                label={t('Image')}
                model={register('image_url', {
                  required: t('Image is required')
                })}
                error={errors.image_url}
                onUpload={openUploading}
                onFininishUpload={closeUploading}
                value={getValues('image_web')}
              />
            </Col>
            <Col md={12}>
              <Switch
                label={t('Status')}
                model={register('status', {
                  required: t('Status is required')
                })}
                error={errors.status}
                defaultChecked={getValues('status') === 1}
              />
            </Col>
            <Col md={12}>
              <IBANInput
                isWallet={getValues()?.company?.code === 'wallet'}
                register={register}
                errors={errors}
              />
            </Col>
          </Row>
        </Container>

        <div className="hstack gap-2 justify-content-end">
          <Button className="btn btn-link btn-secondary fw-medium" outline onClick={close}>
            <i className="ri-close-line me-1 align-middle"></i> {t('Close')}
          </Button>
          <Submit loading={loading} label={t('Update')} />
        </div>
      </form>
    </PopUp>
  )
}
export default UpdateModal
