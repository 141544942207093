import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap'
import { getEditRoute } from '../helper'
import fetch from 'helpers/server'
import showSuccess from 'helpers/SuccessNotfication'
import showError from 'helpers/ErorrNotfication'

function ProductActionButtons({ product: item, openDelete, onSuccess }: any) {
  const { t } = useTranslation()

  const duplicate = async (itemId) => {
    const res = await fetch('/products/' + itemId + '/duplicate')
    if (res?.status) {
      onSuccess && onSuccess()
      return showSuccess(t('Product duplicated successfully'))
    }
    if (res?.status === false) {
      return showError(t('Something went wrong'))
    }
  }

  return (
    <UncontrolledDropdown>
      <DropdownToggle href="#" className="btn btn-secondary btn-sm" tag="button">
        <i className="ri-more-fill" />
      </DropdownToggle>
      <DropdownMenu container={'body'} className="dropdown-menu-end">
        <DropdownItem tag={Link} to={getEditRoute(item?.product_type?.code, item.id, 'edit')}>
          <i className="ri-edit-2-line me-2"></i>

          {t('Edit')}
        </DropdownItem>
        <DropdownItem tag={Link} to={getEditRoute(item?.product_type?.code, item.id, 'view')}>
          <i className="ri-eye-line me-2"></i> {t('View')}
        </DropdownItem>
        <DropdownItem tag={Link} onClick={() => duplicate(item.id)}>
          <i className="ri-file-copy-line me-2"></i> {t('Duplicate')}
        </DropdownItem>
        <DropdownItem tag={Link} to={'/products/stock/' + item.id}>
          <i className="ri-inbox-unarchive-line me-2"></i> {t('Stock')}
        </DropdownItem>
        {item.product_type?.code == 'course_product' && (
          <DropdownItem
            tag={Link}
            to={`/products/chapters/list/${item.id}/${item.product_course_id}`}>
            <i className="ri-book-line me-2"></i> {t('Chapters')}
          </DropdownItem>
        )}

        <DropdownItem divider />
        <DropdownItem onClick={() => openDelete(item.id)}>
          <i className="ri-delete-bin-line me-2"></i>
          {t('Delete')}
        </DropdownItem>
      </DropdownMenu>
    </UncontrolledDropdown>
  )
}

export default ProductActionButtons
