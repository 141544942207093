import BreadCrumb from 'Components/Common/BreadCrumb'
import Loader from 'Components/Common/Loader'
import { Submit } from 'Components/Form'
import React from 'react'
import { FormProvider } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Container, Form } from 'reactstrap'

function ProductsLayout({
  children,
  uploadMethods
}: {
  children: React.ReactNode
  uploadMethods: any
}) {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const { loading, getLoading, formMethods, handleSubmit, update } = uploadMethods

  const submit = async (data: any) => {
    const response = await update(data)
    if (response.status) navigate('/products/list')
  }

  return (
    <div className="page-content">
      <Container fluid>
        <BreadCrumb />

        {getLoading ? (
          <Loader />
        ) : (
          <FormProvider {...formMethods}>
            <Form onSubmit={handleSubmit(submit)}>
              <FormButtons direction="end" loading={loading}></FormButtons>

              {children}

              <FormButtons direction="center" loading={loading}></FormButtons>
            </Form>
          </FormProvider>
        )}
      </Container>
    </div>
  )
}

export default ProductsLayout

export function FormButtons({
  loading,
  direction
}: {
  loading: boolean
  direction?: 'start' | 'end' | 'center'
}) {
  const { t } = useTranslation()
  return (
    <div className={`d-flex justify-content-${direction} my-3 gap-3`}>
      <Submit loading={loading} label={t('Save')} />
      {/* <Button onClick={() => navigate('/products/list')}>{t('Cancel')}</Button> */}
    </div>
  )
}
