import { Button, Col, Container, Row } from 'reactstrap'
import { useTranslation } from 'react-i18next'
import { Input, Submit, ImageUpload, Switch, Select, DatePicker } from 'Components/Form'
import UpdateHock from 'helpers/UpdateHock'
import PopUp from 'Components/PopUp'

type UpdateCustomer = {
  name: string
  lastname: string
  email: string
  dialing_code: string
  phone: string
  gender: string | null
  is_active: number
  image: string
  web_image: string
  birthdate: string | null
}

const UpdateModal = ({ show, close, onSuccess, itemId }: any) => {
  const { t } = useTranslation()

  // init value
  const initForm: UpdateCustomer = {
    name: '',
    lastname: '',
    email: '',
    dialing_code: '',
    phone: '',
    gender: null,
    is_active: 1,
    image: '',
    web_image: '',
    birthdate: null
  }

  // prepare hock
  const {
    register,
    handleSubmit,
    errors,
    loading,
    closeUploading,
    openUploading,
    update,
    getValues,
    getLoading
  } = UpdateHock<UpdateCustomer>({
    path: '/customers',
    initValue: initForm,
    editId: itemId
  })

  const submit = async (data: UpdateCustomer) => {
    const response = await update(data)
    if (response.status) {
      close()
      onSuccess()
    }
  }

  return (
    <PopUp title={t('Update') + ' ' + t('Customer')} show={show} close={close} loading={getLoading}>
      <form onSubmit={handleSubmit(submit)}>
        <Container fluid>
          <Row>
            <Col md={6}>
              <Input
                label={t('Name')}
                placeholder={`${t('Enter')} ${t('Name')}`}
                model={register('name')}
                error={errors.name}
              />
            </Col>
            <Col md={6}>
              <Input
                label={t('Last Name')}
                placeholder={`${t('Enter')} ${t('last name')}`}
                model={register('lastname', {
                  required: t('Last name is required')
                })}
                error={errors.lastname}
              />
            </Col>
            <Col md={12}>
              <Input
                label={t('Email')}
                placeholder={`${t('Enter')} ${t('email')}`}
                model={register('email', {
                  required: t('Email is required'),
                  pattern: {
                    value: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
                    message: t('Invalid email format')
                  }
                })}
                error={errors.email}
              />
            </Col>
            <Col md={6}>
              <Input
                label={t('Dialing Code')}
                placeholder={`${t('Enter')} ${t('Dialing code')}`}
                model={register('dialing_code', {
                  required: t('Dialing code is required')
                })}
                error={errors.dialing_code}
              />
            </Col>
            <Col md={6}>
              <Input
                label={t('Phone')}
                placeholder={`${t('Enter')} ${t('Phone')}`}
                model={register('phone', {
                  required: t('Phone is required')
                })}
                error={errors.phone}
              />
            </Col>
            <Col md={6}>
              <Select
                label={t('Gender')}
                placeholder={`${t('Enter')} ${t('Gender')}`}
                model={register('gender')}
                error={errors.gender}
                options={[
                  { value: 'male', label: t('Male') },
                  { value: 'female', label: t('Female') }
                ]}
                value={getValues('gender')}
              />
            </Col>
            <Col md={6}>
              <DatePicker
                label={t('Birthdate')}
                placeholder={`${t('Enter')} ${t('Birthdate')}`}
                model={register('birthdate')}
                error={errors.birthdate}
                defaultValue={new Date(getValues('birthdate'))}
              />
            </Col>

            <Col md={12}>
              <ImageUpload
                label={t('Image')}
                model={register('image', {
                  required: t('Image is required')
                })}
                error={errors.image}
                onUpload={openUploading}
                onFininishUpload={closeUploading}
                value={getValues('web_image')}
              />
            </Col>
            <Col md={12}>
              {/* <label className="form-label">{t('Active')}</label> */}
              <Switch
                label={t('Active')}
                model={register('is_active')}
                defaultChecked={getValues('is_active') === 1}
              />
            </Col>
          </Row>
        </Container>

        <div className="hstack gap-2 justify-content-end">
          <Button className="btn btn-link btn-secondary fw-medium" outline onClick={close}>
            <i className="ri-close-line me-1 align-middle"></i> {t('Close')}
          </Button>
          <Submit loading={loading} label={t('Update')} />
        </div>
      </form>
    </PopUp>
  )
}
export default UpdateModal
