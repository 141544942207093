import React, { useState } from 'react'
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Nav,
  NavItem,
  NavLink,
  Row,
  Spinner,
  TabContent,
  TabPane
} from 'reactstrap'
import BreadCrumb from 'Components/Common/BreadCrumb'
import FetchHock from 'helpers/FetchHock'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import clsx from 'clsx'
import { Switch } from 'Components/Form'
import ViewDataWithLoading from 'Components/ViewDataWithLoading'
import { useMutation } from '@tanstack/react-query'
import axios from 'axios'
import linkGenerator from 'helpers/linkGenerator'

import CreateModal from '../Components/CreateModal'
import UpdateModal from '../Components/UpdateModal'
import CreateModalOffline from '../Components/CreateModalOffline'
import DeleteModal from 'Components/Common/DeleteModal'
import ImageWithFallback from 'helpers/ImageComponent'

type Company = {
  id: number
  image_url: boolean
  name: string
  type: string | 'offline' | 'cod'
}

const PaymentMethodList = () => {
  document.title = 'Payment methods | Sallatk'

  const { t } = useTranslation()

  const { items, loading, error, invalidate } = FetchHock<Company>({ path: '/company' })

  return (
    <div className="page-content">
      <Container fluid>
        <BreadCrumb />
        <ViewDataWithLoading items={items} loading={loading} error={error}>
          <PaymentMethodTabs tabsData={items} referesh={invalidate} />
        </ViewDataWithLoading>
      </Container>
    </div>
  )
}

export default PaymentMethodList

function PaymentMethodTabs({ tabsData, referesh }: { tabsData: Company[]; referesh: any }) {
  const [activeTab, setActiveTab] = useState(tabsData[0]?.id)
  const toggleTab = (tab: any) => {
    if (activeTab !== tab) {
      setActiveTab(tab)
    }
  }

  return (
    <Row>
      <Col md={12}>
        <Card>
          <CardBody>
            {tabsData?.map((item, index) => {
              if (item && item.type == 'cod') {
                return <CODPaymentMethod key={index} company={item} onSuccess={referesh} />
              }
            })}
          </CardBody>
        </Card>
      </Col>
      <Col md={12}>
        <Card>
          <CardHeader>
            <div>
              <Nav
                className="nav-tabs-custom rounded card-header-tabs border-bottom-0"
                role="tablist">
                {tabsData.map((item, index) => {
                  if (item && item.type != 'cod') {
                    return (
                      <NavItem key={index}>
                        <NavLink
                          href="#"
                          className={clsx({ active: activeTab === item.id })}
                          onClick={() => {
                            toggleTab(item.id)
                          }}>
                          {item.name}
                        </NavLink>
                      </NavItem>
                    )
                  }
                })}
              </Nav>
            </div>
          </CardHeader>
          <CardBody>
            <TabContent activeTab={activeTab}>
              {tabsData?.map((item, index) => {
                if (item.type == 'cod') {
                  return null
                }
                if (item.id == activeTab) {
                  return (
                    <TabPane key={index} tabId={item.id}>
                      <PaymentMethodTabBody companyData={item} />
                    </TabPane>
                  )
                }
              })}
            </TabContent>
          </CardBody>
        </Card>
      </Col>
    </Row>
  )
}

function PaymentMethodTabBody({ companyData }) {
  const type = companyData.type
  const isOffline = type == 'offline'

  return (
    <>
      {isOffline ? (
        <OfflinePaymentMethodTabBody company={companyData} />
      ) : (
        <OnlinePaymentMethodTabBody company={companyData} />
      )}
    </>
  )
}

function OnlinePaymentMethodTabBody({ company }) {
  const type = company.type
  const isCOD = type == 'cod'
  const { t } = useTranslation()

  const [showCreate, changeShowCreate] = useState<boolean>(false)
  const openCreate = () => changeShowCreate(true)
  const closeCreate = () => changeShowCreate(false)

  const { items, loading, error, fetch } = FetchHock<{
    active: boolean
    company_id: number
    id: number
    image_url: string
    title: string
  }>({
    path: '/payments',
    initFilter: {
      company_id: company.id
    }
  })

  return (
    <>
      <Row className="mt-4">
        <div className="mb-2 d-flex justify-content-end">
          <button
            type="button"
            className="btn btn-secondary add-btn "
            onClick={() => {
              openCreate()
            }}>
            <i className="ri-settings-2-line align-middle me-1"></i> {t('Settings')}
          </button>
        </div>

        <ViewDataWithLoading items={items} loading={loading} error={error}>
          {items?.map((item, key: number) => {
            return (
              <React.Fragment key={key}>
                <Col xl={isCOD ? 12 : 3} lg={isCOD ? 12 : 6}>
                  <Card className="ribbon-box right overflow-hidden">
                    <CardBody className="text-center p-4">
                      <ImageWithFallback src={item?.image_url} alt="" height={45} />
                      <h5 className="mb-1 mt-4">
                        <Link to="#" className="link-primary">
                          {item?.title}
                        </Link>
                      </h5>
                      <div className="mt-4 d-flex justify-content-center ">
                        <UpdatePaymentSwitch
                          checkedParam={'active'}
                          item={item}
                          onSuccess={fetch}
                          type={type}
                        />
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </React.Fragment>
            )
          })}
        </ViewDataWithLoading>
      </Row>
      {showCreate && (
        <CreateModal
          data={{ companyId: company.id }}
          close={closeCreate}
          show={openCreate}
          onSuccess={fetch}
        />
      )}
    </>
  )
}
function OfflinePaymentMethodTabBody({ company }) {
  const type = company.type
  const isCOD = type == 'cod'

  const { t } = useTranslation()

  const [showCreate, changeShowCreate] = useState<boolean>(false)
  const openCreate = () => changeShowCreate(true)
  const closeCreate = () => changeShowCreate(false)

  const [itemIdUpdate, changeItemIdUpdate] = useState<number>()
  const [showUpdate, changeShowUpdate] = useState<boolean>(false)
  const openUpdate = () => changeShowUpdate(true)
  const closeUpdate = () => changeShowUpdate(false)

  const { items, openDelete, deleteItem, deleteId, closeDelete, loading, error, fetch } =
    FetchHock<any>({
      path: `/offline-payment`,
      initFilter: {
        company_id: company.id,
        company_type: company.code
      }
    })

  return (
    <>
      <Row className="mt-4">
        <div className="mb-2 d-flex justify-content-end">
          <button type="button" className="btn btn-secondary add-btn " onClick={openCreate}>
            <i className="ri-add-line align-middle me-1"></i> {t('Add')}
          </button>
        </div>

        <ViewDataWithLoading items={items} loading={loading} error={error}>
          {items?.map((item, key: number) => {
            return (
              <React.Fragment key={key}>
                <Col xl={isCOD ? 12 : 3} lg={isCOD ? 12 : 6}>
                  <Card className="ribbon-box right overflow-hidden">
                    <CardBody className="text-center p-4">
                      <ImageWithFallback src={item?.image_web} alt="" height={45} />
                      <h5 className="mb-1 mt-4">
                        <Link to="#" className="link-primary">
                          {item?.title}
                        </Link>
                      </h5>
                      <div className="mt-4 d-flex justify-content-center ">
                        <UpdatePaymentSwitch
                          checkedParam={'status'}
                          item={item}
                          onSuccess={fetch}
                          type={type}
                        />
                      </div>

                      <div className="d-flex gap-2 justify-content-center">
                        <Button
                          size="sm"
                          color="primary"
                          className="mt-3"
                          onClick={() => {
                            changeItemIdUpdate(item.id)
                            openUpdate()
                          }}>
                          {t('Edit')}
                        </Button>
                        <Button
                          size="sm"
                          color="danger"
                          className="mt-3"
                          onClick={() => {
                            openDelete(item.id)
                          }}>
                          {t('Delete')}
                        </Button>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </React.Fragment>
            )
          })}
        </ViewDataWithLoading>
      </Row>
      {deleteId && deleteId > 0 && (
        <DeleteModal
          show={deleteId}
          onDeleteClick={() => {
            deleteItem().then(() => {
              fetch()
            })
          }}
          onCloseClick={() => {
            if (closeDelete) closeDelete()
          }}
        />
      )}
      {showCreate && (
        <CreateModalOffline
          data={{ companyId: company.id, code: company.code }}
          close={closeCreate}
          show={openCreate}
          onSuccess={fetch}
        />
      )}
      {showUpdate && (
        <UpdateModal
          close={closeUpdate}
          show={showUpdate}
          onSuccess={fetch}
          itemId={itemIdUpdate}
        />
      )}
    </>
  )
}

function CODPaymentMethod({ company, onSuccess }) {
  const { t } = useTranslation()
  const CODData = company?.COD

  const { mutate, isPending, variables } = useMutation({
    mutationFn: async () => await axios.put(linkGenerator(`/payments/${CODData?.method_id}`), {}),
    onSettled: async () => {
      await onSuccess()
    }
  })

  return (
    <div className="mt-4 d-flex">
      <Switch
        loading={isPending}
        checked={CODData?.active}
        label={t('Allow COD Payment')}
        onChange={() => mutate()}
      />
    </div>
  )
}

function UpdatePaymentSwitch({ item, onSuccess, type, checkedParam }) {
  const { t } = useTranslation()

  const { mutate, isPending, variables } = useMutation({
    mutationFn: async ({ id, status }: { id: number; status: number }) =>
      await axios.put(
        linkGenerator(
          type == 'offline' ? `/offline-payment/${item.id}/status` : `/payments/${item.id}`
        ),
        {}
      ),
    onSettled: async () => {
      await onSuccess()
    }
  })

  return (
    <Switch
      checked={
        isPending && variables?.id == item?.id ? variables?.status == 1 : item?.[checkedParam]
      }
      label={item.type == 'cod' ? t('Allow COD Payment') : t('Active')}
      onChange={() =>
        mutate({
          id: item.id,
          status: item?.[checkedParam] ? 0 : 1
        })
      }
    />
  )
}
