import { useTranslation } from 'react-i18next'
import { useState } from 'react'
import {
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  Row,
  UncontrolledDropdown
} from 'reactstrap'
import ImageWithFallback from 'helpers/ImageComponent'

import BreadCrumb from 'Components/Common/BreadCrumb'
import IndexTable, { FilterItem, HeaderColumn } from 'Components/IndexTable'
import FetchHock from 'helpers/FetchHock'
import linkGenerator from 'helpers/linkGenerator'
import showSuccess from 'helpers/SuccessNotfication'

import CreateModal from '../components/CreateModal'
import UpdateModal from '../components/UpdateModal'
import UpdatePasswordModal from '../components/UpdatePasswordModal'

import axios from 'axios'

type moderator = {
  id: number
  name: string
  lastname: string
  phone: string
  dialing_code: string
  email: string
  image: string
  mobile_image: string
  role_id: number
  role?: {
    id: number
    name: string
  }
  is_active: 1 | 0
  phone_verified_at: any
  email_verified_at: any
}

const EcommerceOrders = () => {
  const { t } = useTranslation()

  document.title = 'Moderators | Sallatk'

  const fetchHock = FetchHock<moderator>({ path: '/moderators' })

  const headerColumns: HeaderColumn[] = [
    {
      title: 'User',
      accessor: 'name'
    },
    {
      title: 'Role',
      accessor: 'role_id'
    },
    {
      title: 'Active',
      accessor: 'is_active'
    },
    {
      title: 'Email'
    },
    {
      title: 'Phone'
    },
    {
      title: 'Email Verified'
    },
    {
      title: 'Phone Verified'
    },
    {
      title: 'Action'
    }
  ]

  const filterItems: FilterItem[] = [
    {
      type: 'search',
      col: 6,
      placeholder: t('Search for Item'),
      action: (value) => fetchHock.search(value)
    }
  ]

  // create modal logic
  const [showCreate, changeShowCreate] = useState<boolean>(false)
  const openCreate = () => changeShowCreate(true)
  const closeCreate = () => changeShowCreate(false)

  // update modal logic
  const [itemIdUpdate, changeItemIdUpdate] = useState<number>()
  const [showUpdate, changeShowUpdate] = useState<boolean>(false)
  const [showUpdatePassword, changeShowUpdatePassword] = useState<boolean>(false)
  const openUpdate = () => changeShowUpdate(true)
  const closeUpdate = () => changeShowUpdate(false)

  // update password logic
  const openUpdatePassword = () => changeShowUpdatePassword(true)
  const closeUpdatePassword = () => changeShowUpdatePassword(false)

  const block = async (id: number) => {
    const response = await axios.put(linkGenerator(`/moderators/blocked/${id}`))
    if (response.status) showSuccess(t('User blocked status updated successfully'))
  }

  return (
    <div className="page-content">
      <Container fluid>
        <BreadCrumb />

        <Row>
          <Col lg={12}>
            <IndexTable
              title={t('Moderators')}
              addString={t('Add Moderator')}
              addAction={openCreate}
              headerColumns={headerColumns}
              {...fetchHock}
              filters={filterItems}>
              {fetchHock.items?.map((item, index) => (
                <tr key={index}>
                  <td>
                    <div className="d-flex align-items-center">
                      <div className="flex-shrink-0 me-3">
                        <div className="avatar-sm bg-light rounded p-1">
                          <ImageWithFallback
                            src={item.image}
                            alt={item.name + ' ' + item.lastname}
                            className="img-fluid d-block"
                          />
                        </div>
                      </div>
                      <div className="flex-grow-1">
                        <h5 className="fs-14 mb-1">
                          <a href="/ecommerce/product-details" className="text-body">
                            {item.name + ' ' + item.lastname}{' '}
                          </a>
                        </h5>
                        <p className="text-muted mb-0">{item.email}</p>
                      </div>
                    </div>
                  </td>
                  <td>{item.role ? item.role.name : ''}</td>
                  <td>
                    <div className="form-check form-switch-md form-switch form-switch-secondary">
                      <Input
                        className="form-check-input"
                        type="checkbox"
                        role="switch"
                        onChange={() => block(item.id)}
                        defaultChecked={item.is_active === 1}
                      />
                    </div>
                  </td>
                  <td>{item.email}</td>
                  <td>
                    {item.dialing_code} {item.phone}
                  </td>
                  <td>
                    {item.email_verified_at ? (
                      <i className="ri-checkbox-circle-fill text-success"></i>
                    ) : (
                      <i className="ri-close-circle-fill text-danger"></i>
                    )}
                  </td>
                  <td>
                    {item.phone_verified_at ? (
                      <i className="ri-checkbox-circle-fill text-success"></i>
                    ) : (
                      <i className="ri-close-circle-fill text-danger"></i>
                    )}
                  </td>
                  <td>
                    <div className="hstack gap-3 flex-wrap">
                      <UncontrolledDropdown>
                        <DropdownToggle href="#" className="btn btn-secondary btn-sm" tag="button">
                          <i className="ri-more-fill" />
                        </DropdownToggle>
                        <DropdownMenu container={'body'} className="dropdown-menu-end">
                          <DropdownItem
                            onClick={() => {
                              openUpdate()
                              changeItemIdUpdate(item.id)
                            }}>
                         
                              <i className="ri-edit-2-line me-2"></i>
                              {t('Edit')}
                         
                          </DropdownItem>

                          <DropdownItem
                            onClick={() => {
                              openUpdatePassword()
                              changeItemIdUpdate(item.id)
                            }}>
                          
                              <i className="ri-lock-password-line me-2"></i>
                              {t('Change password')}
                       
                          </DropdownItem>

                          <DropdownItem divider />
                          <DropdownItem onClick={() => fetchHock.openDelete(item.id)}>
                         
                              <i className="ri-delete-bin-line me-2"></i>
                          
                            {t('Delete')}
                          </DropdownItem>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </div>
                  </td>
                </tr>
              ))}
            </IndexTable>
          </Col>
        </Row>
      </Container>
      {showCreate && (
        <CreateModal close={closeCreate} show={showCreate} onSuccess={fetchHock.fetch} />
      )}
      {showUpdate && (
        <UpdateModal
          close={closeUpdate}
          show={showUpdate}
          onSuccess={fetchHock.fetch}
          itemId={itemIdUpdate}
        />
      )}
      {showUpdatePassword && (
        <UpdatePasswordModal
          close={closeUpdatePassword}
          show={showUpdatePassword}
          onSuccess={fetchHock.fetch}
          itemId={itemIdUpdate}
        />
      )}
    </div>
  )
}

export default EcommerceOrders
