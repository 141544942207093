import Loader from 'Components/Common/Loader'
import { Spinner } from 'reactstrap'

const Switch = ({
  label,
  model,
  error,
  defaultChecked,
  onChange,
  checked,
  loading
}: {
  label: string
  model?: any
  error?: any
  defaultChecked?: boolean
  checked?: any
  onChange?: (value: any) => void
  loading?: boolean
}) => {
  const randumId = Math.random().toString(36).substring(7)
  // name of input
  const name = model?.name

  return (
    <div className="form-check form-switch-md form-switch form-switch-secondary mb-4">
      {loading ? (
        <span className="me-2">
          <Spinner size={'sm'} color="primary" />
        </span>
      ) : (
        <input
          className={error ? 'form-check-input is-invalid' : 'form-check-input'}
          type="checkbox"
          role="switch"
          id={randumId}
          checked={checked}
          onChange={(event) => {
            const value = event.target.checked ? 1 : 0
            model?.onChange({
              target: {
                name,
                value: value
              }
            })
            if (onChange) onChange(value)
          }}
          defaultChecked={defaultChecked ?? false}
        />
      )}

      <label className="form-check-label" htmlFor={randumId}>
        {label}
      </label>
    </div>
  )
}

export default Switch
