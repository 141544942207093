import { useTranslation } from 'react-i18next'
import {
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  UncontrolledDropdown
} from 'reactstrap'

import BreadCrumb from 'Components/Common/BreadCrumb'
import IndexTable, { FilterItem, HeaderColumn } from 'Components/IndexTable'
import FetchHock from 'helpers/FetchHock'

import { Link } from 'react-router-dom'

interface Role {
  id: number
  name: string
  guard_name: string
  permissions: any[]
}

const RolesList = () => {
  const { t } = useTranslation()

  document.title = 'Roles | Sallatk'

  const fetchHock = FetchHock<Role>({ path: '/role' })

  const headerColumns: HeaderColumn[] = [
    {
      title: 'Id',
      accessor: 'id'
    },
    {
      title: 'Name',
      accessor: 'name'
    },
    {
      title: 'Action'
    }
  ]

  const filterItems: FilterItem[] = [
    {
      type: 'search',
      col: 6,
      placeholder: t('Search for Item'),
      action: (value) => fetchHock.search(value)
    },
    {
      type: 'select',
      col: 6,
      placeholder: t('Select role'),
      options: [
        { label: t('Select role'), value: '' },
        { label: t('Admin'), value: 1 },
        { label: t('Buyer'), value: 2 }
      ],
      action: (value) => console.log(value)
    }
  ]

  return (
    <div className="page-content">
      <Container fluid>
        <BreadCrumb />
        <Row>
          <Col lg={12}>
            <IndexTable
              title={t('Roles')}
              addString={t('Add') + ' ' + t('Role')}
              addRoute="/roles/create"
              headerColumns={headerColumns}
              {...fetchHock}
              filters={filterItems}>
              {fetchHock.items?.map((item, index) => (
                <tr key={index}>
                  <td>
                    <h5 className="fs-14 mb-1">
                      <Link to={`/roles/${item.id}`} className="text-body text-uppercase">
                        #{item.id}{' '}
                      </Link>
                    </h5>
                  </td>
                  <td>{item.name ? item.name : ''}</td>

                  <td>
                    <div className="hstack gap-3 flex-wrap">
                      <UncontrolledDropdown>
                        <DropdownToggle href="#" className="btn btn-secondary btn-sm" tag="button">
                          <i className="ri-more-fill" />
                        </DropdownToggle>
                        <DropdownMenu container={'body'} className="dropdown-menu-end">
                          <DropdownItem tag={Link} to={`/roles/${item.id}`}>
                            <i className="ri-edit-2-line me-2"></i>
                            {t('Edit')}
                          </DropdownItem>

                          <DropdownItem divider />
                          <DropdownItem onClick={() => fetchHock.openDelete(item.id)}>
                            <span className="text-danger">
                              <i className="ri-delete-bin-line me-2"></i>
                              {t('Delete')}
                            </span>
                          </DropdownItem>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </div>
                  </td>
                </tr>
              ))}
            </IndexTable>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default RolesList
